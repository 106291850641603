import React, { useState, useEffect } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import themes from '../../../assets/themes'
import { Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import InputBase from '@mui/material/InputBase'
import Paper from '@mui/material/Paper'
import Avatar from '@mui/material/Avatar'
import Header from '../Header'
import Footer from '../../Footer'
import Sidebar from '../SideBar'
import { fetchPatients } from '../../../api/doctor'

const PatientList = () => {
  const [patients, setPatients] = useState([])

  useEffect(() => {
    async function fetchData() {
      try {
        const patientsData = await fetchPatients()
        setPatients(patientsData)
      } catch (error) {
        console.log('Error fetching patient data', error)
      }
    }

    fetchData()
  }, [])

  return (
    <div>
      {' '}
      {/* Header */}
      <Header />
      {/* Header */}
      {/* Breadcrumb */}
      <div class="breadcrumb-bar">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-12 col-12">
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/Dashboard">Home</a>
                  </li>
                  {/* You should change the breadcrumb Title */}
                  <li class="breadcrumb-item active" aria-current="page">
                    My Patients
                  </li>
                </ol>
              </nav>
              {/* You should change the breadcrumb Title */}
              <h2 class="breadcrumb-title"> My Patients</h2>
            </div>
          </div>
        </div>
      </div>
      {/* Breadcrumb */}
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            {/* Sidebar Component */}
            <div className="col-md-5 col-lg-4 col-xl-3 ">
              <Sidebar />
            </div>
            {/* Sidebar Component */}

            {/* Page Content */}
            <div className="col-md-7 col-lg-8 col-xl-9">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row', // Display elements in the same row
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  sx={{ color: themes.colors.primary }}
                >
                  My Patients
                </Typography>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    border: themes.colors.Lightblue,
                    backgroundColor: 'white',
                    border: `1px solid ${themes.colors.primary}`,
                    borderRadius: '4px',
                    padding: '8px',
                    width: '200px',
                    marginLeft: 'auto',
                    marginRight: '5%',

                    marginBottom: '5px',

                    height: '37px',
                  }}
                >
                  <SearchIcon
                    style={{ color: `#15558D`, marginRight: '2px' }}
                  />

                  <InputBase
                    placeholder="Search..."
                    inputProps={{ label: 'search' }}
                    style={{ color: '#15558D', backgroundColor: 'white' }}
                  />
                </div>
              </div>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          fontWeight: 'bold',
                          color: themes.colors.primary,
                        }}
                      >
                        Profile
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontWeight: 'bold',
                          color: themes.colors.primary,
                        }}
                      >
                        ID
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontWeight: 'bold',
                          color: themes.colors.primary,
                        }}
                      >
                        Name
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{
                          fontWeight: 'bold',
                          color: themes.colors.primary,
                        }}
                      >
                        Blood Group
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontWeight: 'bold',
                          color: themes.colors.primary,
                        }}
                      >
                        Phone Number
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontWeight: 'bold',
                          color: themes.colors.primary,
                        }}
                      >
                        Last Visit
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {patients.map((patient) => (
                      <TableRow key={patient._id}>
                        <TableCell>
                          <Avatar
                            alt={patient.name}
                            src={
                              patient.patientImage == null
                                ? 'Static'
                                : patient.patientImage
                            }
                            sx={{
                              width: 50,
                              height: 50,
                              borderRadius: '50%',
                              margin: 'auto',
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          {patient.patient_id == null
                            ? 'PT776309'
                            : patient.patient_id}
                        </TableCell>
                        <TableCell align="center">
                          {patient.firstname + ' ' + patient.lastname}
                        </TableCell>
                        <TableCell align="center">
                          {patient.blood_group == null
                            ? 'Static'
                            : patient.blood_group}
                        </TableCell>
                        <TableCell align="center">{patient.phone}</TableCell>
                        <TableCell align="center">
                          {patient.lastVisit == null
                            ? '01-01-2023'
                            : patient.lastVisit}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {/* Page Content */}
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
      {/* Footer */}
    </div>
  )
}

export default PatientList
