import axios from './axios'
import '../utils/doctor_info'
import '../utils/patient_info'

export const login = async (username, password) => {
  try {
    const response = await axios.post('/api/users/login', {
      username: username,
      password: password,
    })
    return response
  } catch (error) {
    throw error
  }
}

export const verifyEmail = async (email, otpCode) => {
  try {
    const response = await axios.post('/api/users/verify_email', {
      email: email,
      verificationCode: otpCode,
    })
    return response
  } catch (error) {
    throw error
  }
}
