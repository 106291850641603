import React, { useState, useEffect } from 'react'
import { Card, Typography, CardContent } from '@mui/material'
import { Row, Col } from 'react-bootstrap'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import {
  createTimingsforSpecificPeriod,
  generateOneDayTimeSlots,
} from '../../../api/doctor'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import dayjs from 'dayjs'
import Sidebar from '../SideBar'
import '../../../App.css'
import Header from '../Header'
import Footer from '../../Footer'
import { fetchTimeSlots } from '../../../api/doctor'

const AvailableTimings = () => {
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [filter, updateFilter] = useState(1)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [availableTimings, setAvailableTimings] = useState([])

  const handleDateChange = (date) => {
    setSelectedDate(date)
    fetchAvailableTimings(date)
  }

  const fetchAvailableTimings = async (date) => {
    try {
      const selectedDay = dayjs(date).format('YYYY-MM-DD')
      const response = await fetchTimeSlots(selectedDay)

      const timings = response.map((timeSlot) => ({
        time: timeSlot.start_time,
        isBooked: timeSlot.is_booked,
      }))
      setAvailableTimings(timings)
    } catch (error) {
      console.error('Error fetching available timings:', error)
    }
  }

  const getCurrentDayInfo = () => {
    const currentDate = dayjs(selectedDate)
    const currentDayName = currentDate.format('dddd')
    const currentMonth = currentDate.format('MMMM')
    const currentDayOfMonth = currentDate.format('D')
    return `${currentDayName}, ${currentMonth} ${currentDayOfMonth}`
  }

  const handleResize = () => {
    setScreenWidth(window.innerWidth)
  }
  useEffect(() => {
    fetchAvailableTimings(selectedDate)

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const isMobile = screenWidth < 900

  const addTimeSlotsOneDay = async (date) => {
    try {
      const selectedDay = dayjs(date).format('YYYY-MM-DD')

      const response = await generateOneDayTimeSlots(selectedDay)
    } catch (error) {
      // Handle errors
    }
  }
  const addPeriodTimeSlot = async () => {
    try {
      const selectedDay = filter
      const response = await createTimingsforSpecificPeriod(selectedDay)
    } catch (error) {
      console.log('error adding time slot ', error)
    }
  }

  const handleTimeSlotAdding = () => {
    /*if (filter === 1) {
      addTimeSlotsOneDay(selectedDate)
    } else {
      addPeriodTimeSlot()
    }*/
  }

  return (
    <div>
      {/* Header */}
      <Header />
      {/* Header */}

      {/* Breadcrumb */}
      <div class="breadcrumb-bar">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-12 col-12">
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/Dashboard">Home</a>
                  </li>
                  {/* You should change the breadcrumb Title */}
                  <li class="breadcrumb-item active" aria-current="page">
                    Available Timings
                  </li>
                </ol>
              </nav>
              {/* You should change the breadcrumb Title */}
              <h2 class="breadcrumb-title">Available Time Slots</h2>
            </div>
          </div>
        </div>
      </div>
      {/* Breadcrumb */}

      {/* Body Content */}
      <div class="content">
        <div class="container-fluid">
          <div className="row">
            {/* Sidebar Component */}
            <div className="col-md-5 col-lg-4 col-xl-3 ">
              <Sidebar />
            </div>
            {/* Sidebar Component */}

            {/* Page Content Should be Added here inside this div */}
            <div className="col-md-7 col-lg-8 col-xl-9">
              <Typography
                variant="h4"
                style={{
                  textAlign: 'center',
                  marginTop: '20px',
                  fontWeight: 'bold',
                  color: '#15558D',
                }}
              >
                Available Time Slots
              </Typography>
              <Card
                style={{
                  width: isMobile ? '400px' : '900px',
                  height: isMobile ? '450px' : '450px',
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  marginLeft: '53px',
                  marginTop: '20px',
                  marginBottom: isMobile ? '10px' : undefined,
                }}
              >
                <CardContent>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div style={{ marginTop: '10px', marginLeft: '10px' }}>
                      {isMobile ? (
                        <div style={{ cursor: 'pointer' }}>
                          <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                          />
                        </div>
                      ) : (
                        <DatePicker
                          selected={selectedDate}
                          onChange={handleDateChange}
                          open={true}
                        />
                      )}
                    </div>
                  </LocalizationProvider>
                </CardContent>
                <Card
                  style={{
                    marginTop: isMobile ? '30px' : '85px',
                    marginLeft: isMobile ? '35px' : '160px',
                    overflowY: 'auto',
                    height: '100%',
                    width: '300px',
                    height: '250px',
                    border: '2px solid #15558D',
                  }}
                >
                  <CardContent>
                    <Typography
                      id="day"
                      variant="h6"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        color: '#15558D',
                        fontWeight: 'bold',
                      }}
                    >
                      {getCurrentDayInfo()}
                    </Typography>
                  </CardContent>
                  <CardContent
                    id="time"
                    style={{ display: 'flex', flexWrap: 'wrap' }}
                  >
                    {availableTimings.length != 0 ? (
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          minWidth: '300px',
                        }}
                      >
                        {availableTimings.map((timing, index) => (
                          <Typography
                            key={index}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginRight: '10px',
                              marginBottom: '10px',
                              border: timing.isBooked
                                ? '1px solid #15558D'
                                : '1px solid #d7d4d4',
                              backgroundColor: timing.isBooked
                                ? '#15558D'
                                : '#d7d4d4',
                              padding: '10px',
                              borderRadius: '15px',
                              width: '80px',
                              height: '35px',
                              color: timing.isBooked ? '#fff' : '#15558D',
                              fontWeight: 'bold',
                              fontSize: '16px',
                            }}
                          >
                            {timing.time}
                          </Typography>
                        ))}
                      </div>
                    ) : (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            marginBottom: '10px',
                          }}
                        >
                          <Row>
                            <Col>
                              <Typography
                                style={{
                                  color: '#15558D',
                                  fontSize: '15px',
                                }}
                              >
                                Select Filter
                              </Typography>
                            </Col>
                            <Col>
                              {' '}
                              <Select
                                id="filter-select"
                                value={filter}
                                variant="outlined"
                                sx={{ width: '250px' }}
                                label="Filter"
                                onChange={(e) => updateFilter(e.target.value)}
                              >
                                <MenuItem value={1}>1 Day</MenuItem>
                                <MenuItem value={7}>1 Week</MenuItem>
                                <MenuItem value={30}>1 Month</MenuItem>
                                <MenuItem value={90}>3 Months</MenuItem>
                                <MenuItem value={180}>6 Months</MenuItem>
                              </Select>
                            </Col>
                          </Row>
                        </div>
                        <button
                          onClick={() => handleTimeSlotAdding()}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '250px',
                            height: '40px',
                            backgroundColor: '#15558D',
                            color: '#fff',
                            fontWeight: 'bold',
                            fontSize: '20px',
                            borderRadius: '15px',
                            borderStyle: 'none',
                            marginTop: '15px',
                            marginLeft: '5px',
                          }}
                        >
                          Generate Timings
                        </button>
                      </>
                    )}
                  </CardContent>
                </Card>
              </Card>
            </div>
          </div>
        </div>
      </div>
      {/* Body Content */}

      {/* Footer */}
      <Footer />
      {/* Footer */}
    </div>
  )
}

export default AvailableTimings
