import React, { useState } from 'react'
import {
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import Sidebar from '../SideBar'
import Header from '../Header'
import Footer from '../../Footer'
import { changePassword } from '../../../api/doctor'
const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (newPassword !== confirmNewPassword) {
      alert('New password and confirmation password do not match!')
    } else {
      try {
        const response = await changePassword(currentPassword, newPassword)
        if (response.statusCode === 200) {
          alert('Password Changed Successfully!')
        } else {
          alert(response.message)
        }
      } catch (error) {
        console.error('Error changing password', error)
      }
    }
  }

  return (
    <div>
      <Header />

      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/Dashboard">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    All Doctors
                  </li>
                </ol>
              </nav>
              <h2 className="breadcrumb-title">All Doctors</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5 col-lg-4 col-xl-3 ">
              <Sidebar />
            </div>

            <Grid item xs={12} md={8} lg={9}>
              <Paper elevation={3} style={{ padding: '20px' }}>
                <form onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    label="Current Password"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                  />
                  <TextField
                    fullWidth
                    label="New Password"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <TextField
                    fullWidth
                    label="Confirm New Password"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                  />
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    style={{ backgroundColor: '#15558d', marginTop: '10px' }}
                  >
                    Save Changes
                  </Button>
                </form>
              </Paper>
            </Grid>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
export default ChangePassword
