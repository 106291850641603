import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom'
import Home from './components/Home'
import WaitingPage from './components/Doctors/WaitingPage'
import ChangePassword from './components/Doctors/ChangePassword'
import ProfileSettings from './components/Doctors/ProfileSettings'
import Booking from './components/Doctors/AddAppointment/Booking'
import BookingSuccess from './components/Doctors/AddAppointment/BookingSuccess'
import AvailableTimings from './components/Doctors/AvailableTimings'
import OpeningHours from './components/Doctors/OpeningHours'
import Reviews from './components/Doctors/Reviews'
import Dashboard from './components/Doctors/Dashboard'
import ViewAppointment from './components/Doctors/AddAppointment/ViewAppointment'
import Checkout from './components/Doctors/AddAppointment/Checkout'
import PatientProfile from './components/Doctors/PatientProfile'
import Register from './components/Register'
import Login from './components/Login'
import NotFound from './components/404'
import BlankPage from './components/Doctors/Blank'
import DoctorCalendar from './components/Doctors/Calendar'
import PatientCalendar from './components/Patients/Calendar'
import PatientList from './components/Doctors/PatientList'
import PatientDashboard from './components/Patients/Dashboard'
import SearchDoctor from './components/Patients/SearchDoctor'
import PatientFavourites from './components/Patients/Favourites'
import DoctorProfile from './components/Patients/DoctorProfile'
import AllDoctors from './components/Patients/AllDoctors'
import MedicalRecords from './components/Patients/MedicalRecords'
import PatientBooking from './components/Patients/Booking'
import PatientBookingSuccess from './components/Patients/Booking/BookingSuccess'
import ViewPatientAppointment from './components/Patients/Booking/ViewAppointment'
import PatientCheckout from './components/Patients/Booking/Checkout'
import PatientProfileSettings from './components/Patients/ProfileSettings'
import ForgetPassword from './components/Login/ForgetPassword'
import { isDoctorValid, isPatientValid, isTokenValid } from './utils/auth'
import './App.css'

const App = () => {
  const isDoctorSignedIn = !!isTokenValid() && !!isDoctorValid() // Check if doctor JWT token is found
  const isPatientSignedIn = !!isTokenValid() && !!isPatientValid()
  return (
    <Router basename="/">
      <div style={{ paddingTop: '25px' }}>
        <Routes>
          {/* HomePage */}
          <Route path="/" element={<Home />} />

          {/* Doctor Activities */}
          <Route
            path="/Dashboard"
            element={
              isDoctorSignedIn ? <Dashboard /> : <Navigate to="/Login" />
            }
          />
          <Route
            path="/ProfileSettings"
            element={
              isDoctorSignedIn ? <ProfileSettings /> : <Navigate to="/Login" />
            }
          />
          <Route
            path="/AvailableTimings"
            element={
              isDoctorSignedIn ? <AvailableTimings /> : <Navigate to="/Login" />
            }
          />
          <Route
            path="/OpeningHours"
            element={
              isDoctorSignedIn ? <OpeningHours /> : <Navigate to="/Login" />
            }
          />
          <Route
            path="/ChangePassword"
            element={
              isDoctorSignedIn ? <ChangePassword /> : <Navigate to="/Login" />
            }
          />
          <Route
            path="/Reviews"
            element={isDoctorSignedIn ? <Reviews /> : <Navigate to="/Login" />}
          />

          <Route
            path="/AddAppointment"
            element={isDoctorSignedIn ? <Booking /> : <Navigate to="/Login" />}
          />
          <Route
            path="/Checkout"
            element={isDoctorSignedIn ? <Checkout /> : <Navigate to="/Login" />}
          />
          <Route
            path="/BookingSuccess"
            element={
              isDoctorSignedIn ? <BookingSuccess /> : <Navigate to="/Login" />
            }
          />
          <Route
            path="/ForgetPassword"
            element={
              isDoctorSignedIn ? (
                <ForgetPassword />
              ) : (
                <Navigate to="/ForgetPassowrd" />
              )
            }
          />
          <Route
            path="/MedicalRecords"
            element={
              isPatientSignedIn ? <MedicalRecords /> : <Navigate to="/Login" />
            }
          />
          <Route
            path="/ViewAppointment"
            element={
              isDoctorSignedIn ? <ViewAppointment /> : <Navigate to="/Login" />
            }
          />
          <Route
            path="/PatientList"
            element={
              isDoctorSignedIn ? <PatientList /> : <Navigate to="/Login" />
            }
          />
          <Route
            path="/PatientProfile"
            element={
              isDoctorSignedIn ? <PatientProfile /> : <Navigate to="/Login" />
            }
          />
          <Route path="/BlankPage" element={<BlankPage />} />
          <Route
            path="/DoctorCalendar"
            element={
              isDoctorSignedIn ? <DoctorCalendar /> : <Navigate to="/Login" />
            }
          />
          {/* Login Activities */}
          <Route path="/Register" element={<Register />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/WaitingPage" element={<WaitingPage />} />

          {/*Patient Activities*/}
          <Route
            path="/PatientDashboard"
            element={
              isPatientSignedIn ? (
                <PatientDashboard />
              ) : (
                <Navigate to="/Login" />
              )
            }
          />
          <Route
            path="/PatientCalendar"
            element={
              isPatientSignedIn ? <PatientCalendar /> : <Navigate to="/Login" />
            }
          />
          <Route
            path="/SearchDoctor"
            element={
              isPatientSignedIn ? <SearchDoctor /> : <Navigate to="/Login" />
            }
          />
          <Route
            path="/Favourites"
            element={
              isPatientSignedIn ? (
                <PatientFavourites />
              ) : (
                <Navigate to="/Login" />
              )
            }
          />
          <Route
            path="/DoctorProfile"
            element={
              isPatientSignedIn ? <DoctorProfile /> : <Navigate to="/Login" />
            }
          />
          <Route
            path="/AllDoctors"
            element={
              isPatientSignedIn ? <AllDoctors /> : <Navigate to="/Login" />
            }
          />
          <Route
            path="/MedicalRecords"
            element={
              isPatientSignedIn ? <MedicalRecords /> : <Navigate to="/Login" />
            }
          />
          <Route
            path="/PatientBooking"
            element={
              isPatientSignedIn ? <PatientBooking /> : <Navigate to="/Login" />
            }
          />
          <Route
            path="/PatientCheckout"
            element={
              isPatientSignedIn ? <PatientCheckout /> : <Navigate to="/Login" />
            }
          />
          <Route
            path="/PatientProfileSettings"
            element={
              isPatientSignedIn ? (
                <PatientProfileSettings />
              ) : (
                <Navigate to="/Login" />
              )
            }
          />
          <Route
            path="/PatientBookingSuccess"
            element={
              isPatientSignedIn ? (
                <PatientBookingSuccess />
              ) : (
                <Navigate to="/Login" />
              )
            }
          />
          <Route
            path="/ViewPatientAppointment"
            element={
              isPatientSignedIn ? (
                <ViewPatientAppointment />
              ) : (
                <Navigate to="/Login" />
              )
            }
          />

          {/* Catch-all route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  )
}

export default App
