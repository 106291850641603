import React from 'react'
import Sidebar from '../SideBar'
import '../../../App.css'
import Header from '../Header'
import Footer from '../../Footer'

const Blank = () => {
  return (
    <div>
      {/* Header */}
      <Header />
      {/* Header */}

      {/* Breadcrumb */}
      <div class="breadcrumb-bar">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-12 col-12">
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/Dashboard">Home</a>
                  </li>
                  {/* You should change the breadcrumb Title */}
                  <li class="breadcrumb-item active" aria-current="page">
                    Dashboard
                  </li>
                </ol>
              </nav>
              {/* You should change the breadcrumb Title */}
              <h2 class="breadcrumb-title">Blank Page</h2>
            </div>
          </div>
        </div>
      </div>
      {/* Breadcrumb */}
      {/* Body Content */}
      <div class="content">
        <div class="container-fluid">
          <div className="row">
            {/* Sidebar Component */}
            <div className="col-md-5 col-lg-4 col-xl-3 ">
              <Sidebar />
            </div>
            {/* Sidebar Component */}

            {/* Page Content Should be Added here inside this div */}
            <div className="col-md-7 col-lg-8 col-xl-9">
              {/* Add Here your Page Content (Table,Image,...) */}
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
      {/* Footer */}
    </div>
  )
}

export default Blank
