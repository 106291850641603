import React, { useRef, useEffect } from 'react'
import Sidebar from '../SideBar'
import Header from '../Header'
import Footer from '../../Footer'
import { Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import html2canvas from 'html2canvas'
import downloadIcon from '../../../assets/images/download.png'
import Card from '@mui/material/Card'
import { Row, Col } from 'react-bootstrap'

export const ViewPatientAppointment = () => {
  const navigate = useNavigate()
  const contentRef = useRef(null)

  const handleDownloadClick = () => {
    const node = contentRef.current
    const isMobile = window.innerWidth < 900

    if (isMobile) {
      node.style.width = '900px'
    }

    const returnBackButton = node.querySelector('.return-back-button')
    const downloadButton = node.querySelector('.download-button')
    if (returnBackButton && downloadButton) {
      returnBackButton.style.display = 'none'
      downloadButton.style.display = 'none'
    }

    html2canvas(node).then((canvas) => {
      node.style.width = 'auto'

      if (returnBackButton && downloadButton) {
        returnBackButton.style.display = 'block'
        downloadButton.style.display = 'inline'
      }

      const dataUrl = canvas.toDataURL('image/png')
      const link = document.createElement('a')
      link.href = dataUrl
      link.download = 'Appointment-Walter Roberson.png'
      link.click()
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      {/* Header */}
      <Header />
      {/* Header */}

      {/* Breadcrumb */}
      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/Dashboard">Home</a>
                  </li>
                  {/* You should change the breadcrumb Title */}
                  <li className="breadcrumb-item active" aria-current="page">
                    View Appointment
                  </li>
                </ol>
              </nav>
              {/* You should change the breadcrumb Title */}
              <h2 className="breadcrumb-title">View Appointment</h2>
            </div>
          </div>
        </div>
      </div>
      {/* Breadcrumb */}
      {/* Body Content */}
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            {/* Sidebar Component */}
            <div className="col-md-5 col-lg-4 col-xl-3 ">
              <Sidebar />
            </div>

            <div className="col-md-7 col-lg-8 col-xl-9">
              <Card style={{ padding: 30, marginBottom: 30 }}>
                <div className="div-invoice-content" ref={contentRef}>
                  <div className="div-row2">
                    <img
                      src={require('../../../../src/assets/images/logo.png')}
                      alt="Logo"
                      className="logo-png"
                    />
                    <button
                      onClick={handleDownloadClick}
                      className="btn btn-primary mt-1 download-button"
                      style={{
                        float: 'right',
                        backgroundColor: '#15558d',
                      }}
                    >
                      <img src={downloadIcon} style={{ height: '25px' }} />{' '}
                      Download
                    </button>
                    <div className="p-invoice-details-wrapper">
                      <div className="p-invoice-details">
                        <p className="strong-appt">
                          <span className="text-wrapper">Appt #:</span>
                          <span className="span"> 00124</span>
                        </p>
                        <p className="strong-issued-in">
                          <span className="text-wrapper">Issued in:</span>
                          <span className="span"> 20/07/2023</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="div">
                    <div className="div-invoice-info-wrapper">
                      <div className="div-invoice-info">
                        <Row>
                          <Col>
                            <div className="strong-doctor-clinic">
                              Doctor / Clinic
                            </div>
                            <div className="dr-darren-elder-wrapper">
                              <p className="dr-darren-elder">
                                Dr. Darren Elder
                                <br />
                                806 Twin Willow Lane, Old Forge,
                                <br />
                                Newyork, USA
                              </p>
                            </div>
                          </Col>
                          <Col>
                            {' '}
                            <div className="div-invoice-info-wrapper">
                              <div className="div-invoice-info-2">
                                <div className="strong-patient">Patient</div>
                                <div className="walter-roberson-wrapper">
                                  <div className="walter-roberson">
                                    Walter Roberson
                                    <br />
                                    <br />
                                    Beirut
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="px-4 py-2 overflow-auto">
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Description</th>
                              <th>Date</th>
                              <th>Hours</th>
                              <th>Note</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>General Consultation</td>
                              <td>17-11-2013</td>
                              <td>10:00</td>
                              <td>Nothing Urgent</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <div className="div-other-info">
                        <div className="heading-other mt-3">
                          Other information
                        </div>
                        <p className="p">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Vivamus sed dictum ligula, cursus blandit risus.
                        </p>
                        <div style={{ display: 'flex' }}>
                          <button
                            onClick={() => navigate('/Dashboard')}
                            className="btn btn-secondary mt-1 return-back-button"
                            style={{
                              backgroundColor: '#15558d',
                              marginRight: '20px',
                            }}
                          >
                            Return Back
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
      {/* Footer */}
    </div>
  )
}
export default ViewPatientAppointment
