import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import themes from '../../../assets/themes'

import {
  Card,
  CardContent,
  Typography,
  Rating,
  Pagination,
  PaginationItem,
  Checkbox,
  Slider,
  TextField,
} from '@mui/material'
import { FaFilter } from 'react-icons/fa'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Logo from '../../../assets/images/icons/specialities01.png'
import Header from '../../Patients/Header'
import Footer from '../../Footer'
import { searchDoctor } from '../../../api/doctor'
import { getDoctorRating } from '../../../api/patient'
import theme from '../../../assets/themes'
import Button from '@mui/material/Button'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

const SearchDoctor = () => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [doctorRatings, setDoctorRatings] = useState({})
  const [filteredDoctors, setFilteredDoctors] = useState([])
  const [selectedGenders, setSelectedGenders] = useState({
    Male: false,
    Female: false,
  })

  const [selectedSpecializations, setSelectedSpecializations] = useState({
    mouth: false,
    eye: false,
    Urology: false,
    Ophthalmology: false,
    Cardiology: false,
  })

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target

    if (name in selectedGenders) {
      setSelectedGenders({
        ...selectedGenders,
        [name]: checked,
      })
    } else if (name in selectedSpecializations) {
      setSelectedSpecializations({
        ...selectedSpecializations,
        [name]: checked,
      })
    }
  }

  const [range, setRange] = React.useState([0, 1000])
  function handleChanges(event, newValue) {
    setRange(newValue)
    console.log('Range Updated:', newValue)
  }

  useEffect(() => {
    async function fetchDoctors() {
      try {
        const data = {
          firstname: firstName,
          lastname: lastName,
          gender: selectedGenders.Male
            ? 'Male'
            : selectedGenders.Female
            ? 'Female'
            : '',
          consultationFeeFrom: range[0],
          consultationFeeTo: range[1],
          specialization: Object.keys(selectedSpecializations)
            .filter((key) => selectedSpecializations[key])
            .join(', '),
        }
        console.log(data)
        const doctorsData = await searchDoctor(data)

        const ratingsMap = {}
        for (const doctor of doctorsData) {
          try {
            const rating = await getDoctorRating(doctor._id)
            ratingsMap[doctor._id] = rating
          } catch (error) {
            console.error(
              `Error fetching rating for doctor with _id ${doctor._id}:`,
              error
            )
            ratingsMap[doctor._id] = 0
          }
        }

        setFilteredDoctors(doctorsData)
        setDoctorRatings(ratingsMap)
      } catch (error) {
        console.log('Error fetching Time Template', error)
      }
    }

    fetchDoctors()
  }, [firstName, lastName, selectedGenders, range, selectedSpecializations])

  const navigate = useNavigate()

  const handleResetClick = () => {
    setFirstName('')
    setLastName('')
    setSelectedGenders({
      Male: false,
      Female: false,
    })
    setSelectedSpecializations({
      mouth: false,
      eye: false,
      Urology: false,
      Ophthalmology: false,
      Cardiology: false,
    })
    setRange([0, 1000])
  }

  const handleViewProfileClick = (doctorData, doctorRating) => {
    navigate('/DoctorProfile', { state: { doctorData, doctorRating } })
  }

  const handleBookAppointmentClick = (doctorData) => {
    navigate('/PatientBooking', { state: { doctorData } })
  }

  const [page, setPage] = useState(1)
  const doctorsPerPage = 5

  const doctorsToDisplay = filteredDoctors.slice(
    (page - 1) * doctorsPerPage,
    page * doctorsPerPage
  )

  // const [sortOrder, setSortOrder] = useState(null)
  // const handleSortClick = () => {
  //   if (sortOrder === null) {
  //     setSortOrder('ascending')
  //     doctorsToDisplay.sort((a, b) => a.firstname.localeCompare(b.firstname))
  //   } else {
  //     setSortOrder(null)
  //   }
  // }

  const [sidebarVisible, setSidebarVisible] = useState(window.innerWidth > 500)
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible)
  }

  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [isBookmarked, setIsBookmarked] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)

  const handleBookmarkClick = () => {
    setIsBookmarked(!isBookmarked)
    setOpenAlert(true)
  }
  const handleAlertClose = () => {
    setOpenAlert(false)
  }
  useEffect(() => {
    const handleResize = () => {
      setSidebarVisible(window.innerWidth > 500)
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const isMobile = screenWidth <= 500

  function capitalizeFirstLetter(text) {
    return text.charAt(0).toUpperCase() + text.slice(1)
  }

  return (
    <div>
      <Header />

      <div class="breadcrumb-bar">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-12 col-12">
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/PatientDashboard">Home</a>
                  </li>
                  {/* You should change the breadcrumb Title */}
                  <li class="breadcrumb-item active" aria-current="page">
                    Search Doctor
                  </li>
                </ol>
              </nav>
              {/* You should change the breadcrumb Title */}
              <h2 class="breadcrumb-title">Find a Doctor</h2>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : undefined,
          marginTop: '50px',
        }}
      >
        <div>
          {window.innerWidth <= 500 && (
            <div style={{ display: 'flex' }}>
              <button
                onClick={toggleSidebar}
                style={{
                  width: '70px',
                  height: '40px',
                  border: '1px solid #dfdfe9',
                  borderRadius: '5px',
                  background: '#dfdfe9',
                  padding: '5px',
                  marginLeft: '20px',
                }}
              >
                <FaFilter style={{ color: '#1F2937', marginRight: '5px' }} />
                Filter
              </button>
              {/* <div
                id="sort"
                style={{ marginLeft: '300px', marginTop: '-10px' }}
              >
                <Typography style={{ color: '#6B7280', marginLeft: '10px' }}>
                  Sort By
                </Typography>
                <button
                  onClick={handleSortClick}
                  style={{
                    fontWeight: 'bold',
                    backgroundColor: sortOrder ? '#20C0F3' : '#dfdfe9',
                    color: sortOrder ? '#fff' : undefined,
                    border: '1px',
                    borderRadius: '5px',
                    padding: '5px',
                    width: '70px',
                  }}
                >
                  {sortOrder === null ? 'A To Z' : 'Reset'}
                </button>
              </div> */}
            </div>
          )}
          <div
            id="sidebar"
            style={{
              width: sidebarVisible ? '300px' : '0',
              height: sidebarVisible ? '1150px' : '0',
              marginLeft: isMobile ? '20px' : '20px',
              marginTop: isMobile ? '10px' : '0px',
              marginBottom: sidebarVisible ? '150px' : '10px',
              transition:
                'width 0.3s ease-in-out, height 0.3s ease-in-out, margin-bottom 0.3s ease-in-out',
            }}
          >
            <Card
              sx={{
                height: '100%',
                overflowX: 'auto',
                overflowY: 'auto',
                WebkitOverflowScrolling: 'touch',
                MsOverflowStyle: '-ms-autohiding-scrollbar',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0)',
                border: '2px solid #F3F4F6',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
            >
              <CardContent sx={{ margin: 0, padding: 0 }}>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    width: '300px',
                    padding: '14px 237px 15.59px 20px',
                    borderRadius: '0px 0px 0px 0px',
                    backgroundColor: '#F2F6F6',
                  }}
                >
                  Filter
                </Typography>
                <div style={{ marginLeft: '20px' }}>
                  <Typography sx={{ fontWeight: 'bold', marginTop: '16px' }}>
                    Name
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    label="First Name"
                    variant="outlined"
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    sx={{ mt: '5px' }}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Last Name"
                    variant="outlined"
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    sx={{ mt: '10px' }}
                  />
                </div>
                <div
                  id="gender"
                  style={{ marginLeft: '20px', marginTop: '24px' }}
                >
                  <Typography sx={{ fontWeight: 'bold' }}>Gender</Typography>
                  <Typography sx={{ marginLeft: '-10px' }}>
                    <Checkbox
                      name="Male"
                      checked={selectedGenders.Male}
                      onChange={handleCheckboxChange}
                    />{' '}
                    <span
                      style={{
                        color: '#6B7280',
                        fontSize: '15px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '22.5px',
                      }}
                    >
                      Male
                    </span>
                    <br />
                    <Checkbox
                      name="Female"
                      checked={selectedGenders.Female}
                      onChange={handleCheckboxChange}
                    />{' '}
                    <span
                      style={{
                        color: '#6B7280',
                        fontSize: '15px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '22.5px',
                      }}
                    >
                      Female
                    </span>
                  </Typography>
                </div>
                <div style={{ marginTop: '10px', marginLeft: '20px' }}>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    Availability
                  </Typography>
                  <Typography sx={{ marginLeft: '-10px' }}>
                    <Checkbox />{' '}
                    <span
                      style={{
                        color: '#6B7280',
                        fontSize: '15px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '22.5px',
                      }}
                    >
                      Available Today
                    </span>
                    <br />
                    <Checkbox />{' '}
                    <span
                      style={{
                        color: '#6B7280',
                        fontSize: '15px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '22.5px',
                      }}
                    >
                      Available Tomorrow
                    </span>
                    <br />
                    <Checkbox />{' '}
                    <span
                      style={{
                        color: '#6B7280',
                        fontSize: '15px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '22.5px',
                      }}
                    >
                      Available in 7 Days
                    </span>
                    <br />
                    <Checkbox />{' '}
                    <span
                      style={{
                        color: '#6B7280',
                        fontSize: '15px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '22.5px',
                      }}
                    >
                      Available in Next 30 Days
                    </span>
                  </Typography>
                </div>
                <div style={{ marginTop: '10px', marginLeft: '20px' }}>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    Consultation Fee
                  </Typography>
                  <div
                    style={{
                      width: '250px',
                      padding: '20px',
                      marginLeft: '-20px',
                    }}
                  >
                    <Slider
                      value={range}
                      onChange={handleChanges}
                      valueLabelDisplay="auto"
                      min={0}
                      max={1000}
                    />
                    Price{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      ${range[0]} - ${range[1]}
                    </span>
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      Speciality
                    </Typography>
                    <Typography sx={{ marginLeft: '-10px' }}>
                      <Checkbox
                        name="mouth"
                        checked={selectedSpecializations.mouth}
                        onChange={handleCheckboxChange}
                      />{' '}
                      <span
                        style={{
                          color: '#6B7280',
                          fontSize: '15px',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: '22.5px',
                        }}
                      >
                        Mouth
                      </span>
                      <br />
                      <Checkbox
                        name="eye"
                        checked={selectedSpecializations.eye}
                        onChange={handleCheckboxChange}
                      />{' '}
                      <span
                        style={{
                          color: '#6B7280',
                          fontSize: '15px',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: '22.5px',
                        }}
                      >
                        Eye
                      </span>
                      <br />
                      <Checkbox
                        name="Urology"
                        checked={selectedSpecializations.Urology}
                        onChange={handleCheckboxChange}
                      />{' '}
                      <span
                        style={{
                          color: '#6B7280',
                          fontSize: '15px',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: '22.5px',
                        }}
                      >
                        Urology
                      </span>
                      <br />
                      <Checkbox
                        name="Ophthalmology"
                        checked={selectedSpecializations.Ophthalmology}
                        onChange={handleCheckboxChange}
                      />{' '}
                      <span
                        style={{
                          color: '#6B7280',
                          fontSize: '15px',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: '22.5px',
                        }}
                      >
                        Ophthalmology
                      </span>
                      <br />
                      <Checkbox
                        name="Cardiology"
                        checked={selectedSpecializations.Cardiology}
                        onChange={handleCheckboxChange}
                      />{' '}
                      <span
                        style={{
                          color: '#6B7280',
                          fontSize: '15px',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: '22.5px',
                        }}
                      >
                        Cardiology
                      </span>
                    </Typography>
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      Experience
                    </Typography>
                    <Typography sx={{ marginLeft: '-10px' }}>
                      <Checkbox />{' '}
                      <span
                        style={{
                          color: '#6B7280',
                          fontSize: '15px',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: '22.5px',
                        }}
                      >
                        1-5 Years
                      </span>
                      <br />
                      <Checkbox />{' '}
                      <span
                        style={{
                          color: '#6B7280',
                          fontSize: '15px',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: '22.5px',
                        }}
                      >
                        5+ Years
                      </span>
                    </Typography>
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      By Rating
                    </Typography>
                    <Typography sx={{ marginLeft: '-10px' }}>
                      <div style={{ display: 'flex' }}>
                        <Checkbox />
                        <Rating
                          sx={{ marginLeft: '5px', marginTop: '8px' }}
                          name="half-rating-read"
                          defaultValue="5.0"
                          precision={0.5}
                          readOnly
                        />
                        <span
                          style={{
                            marginLeft: '80px',
                            marginTop: '10px',
                            color: '#6B7280',
                            fontSize: '15px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '22.5px',
                          }}
                        >
                          (40)
                        </span>
                        <br />
                      </div>

                      <div style={{ display: 'flex' }}>
                        <Checkbox />
                        <Rating
                          sx={{ marginLeft: '5px', marginTop: '8px' }}
                          name="half-rating-read"
                          defaultValue="4.0"
                          precision={0.5}
                          readOnly
                        />
                        <span
                          style={{
                            marginLeft: '80px',
                            marginTop: '10px',
                            color: '#6B7280',
                            fontSize: '15px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '22.5px',
                          }}
                        >
                          (35)
                        </span>
                        <br />
                      </div>
                      <div style={{ display: 'flex' }}>
                        <Checkbox />
                        <Rating
                          sx={{ marginLeft: '5px', marginTop: '8px' }}
                          name="half-rating-read"
                          defaultValue="3.0"
                          precision={0.5}
                          readOnly
                        />
                        <span
                          style={{
                            marginLeft: '80px',
                            marginTop: '10px',
                            color: '#6B7280',
                            fontSize: '15px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '22.5px',
                          }}
                        >
                          (20)
                        </span>
                        <br />
                      </div>
                      <div style={{ display: 'flex' }}>
                        <Checkbox />
                        <Rating
                          sx={{ marginLeft: '5px', marginTop: '8px' }}
                          name="half-rating-read"
                          defaultValue="2.0"
                          precision={0.5}
                          readOnly
                        />
                        <span
                          style={{
                            marginLeft: '80px',
                            marginTop: '10px',
                            color: '#6B7280',
                            fontSize: '15px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '22.5px',
                          }}
                        >
                          (10)
                        </span>
                        <br />
                      </div>
                      <div style={{ display: 'flex' }}>
                        <Checkbox />
                        <Rating
                          sx={{ marginLeft: '5px', marginTop: '8px' }}
                          name="half-rating-read"
                          defaultValue="1.0"
                          precision={0.5}
                          readOnly
                        />
                        <span
                          style={{
                            marginLeft: '80px',
                            marginTop: '10px',
                            color: '#6B7280',
                            fontSize: '15px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '22.5px',
                          }}
                        >
                          (05)
                        </span>
                      </div>
                    </Typography>
                  </div>
                </div>
              </CardContent>
            </Card>
            {sidebarVisible && (
              <div style={{ display: 'flex', marginLeft: '10px' }}>
                <button
                  onClick={handleResetClick}
                  style={{
                    display: 'flex',
                    marginTop: '48px',
                    color: '#20C0F3',
                    width: '119px',
                    padding: '8px 36.67px 9.5px 37.33px',
                    justifyContent: 'center',
                    alignItems: 'flexStart',
                    flexShrink: '0',
                    borderRadius: '6px',
                    border: '2px solid #20C0F3',
                    background: '#fff',
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = '#20C0F3'
                    e.target.style.color = '#fff'
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = '#fff'
                    e.target.style.color = '#20C0F3'
                  }}
                >
                  Reset
                </button>
              </div>
            )}
          </div>
        </div>
        <div
          id="main"
          style={{
            marginLeft: isMobile ? '0px' : '50px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: isMobile ? 'center' : undefined,
          }}
        >
          <div style={{ display: 'flex' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: isMobile
                  ? 'column'
                  : screenWidth > 700 && screenWidth < 900
                  ? 'column'
                  : undefined,
              }}
            >
              <Typography
                style={{
                  fontWeight: '500',
                  fontSize: '20px',
                  fontStyle: 'normal',
                  lineHeight: '24px',
                  color: '#1F2937',

                  marginLeft:
                    screenWidth > 700 && screenWidth < 900
                      ? '-15px'
                      : undefined,
                }}
              >
                {filteredDoctors.length}{' '}
                {filteredDoctors.length === 1 ? 'Doctor' : 'Doctors'} found
              </Typography>
              {!isMobile && (
                <div
                  style={{
                    marginLeft:
                      screenWidth > 700 && screenWidth < 900
                        ? undefined
                        : '450px',
                    marginTop:
                      screenWidth > 700 && screenWidth < 900
                        ? '20px'
                        : undefined,
                  }}
                >
                  {/* <div style={{ marginLeft: '250px' }}>
                    <Typography
                      style={{ color: '#6B7280', marginLeft: '20px' }}
                    >
                      Sort By
                    </Typography>
                    <button
                      onClick={handleSortClick}
                      style={{
                        fontWeight: 'bold',
                        backgroundColor: sortOrder ? '#20C0F3' : '#dfdfe9',
                        color: sortOrder ? '#fff' : undefined,
                        border: '1px',
                        borderRadius: '5px',
                        padding: '5px',
                        width: '100px',
                      }}
                    >
                      {sortOrder === null ? 'A To Z' : 'Reset'}
                    </button>
                  </div> */}
                </div>
              )}
            </div>
          </div>
          {doctorsToDisplay.map((doctorsData) => (
            <Card
              key={doctorsData._id}
              sx={{
                width: isMobile
                  ? '300px'
                  : screenWidth > 700 && screenWidth < 900
                  ? '300px'
                  : '966px',
                height:
                  screenWidth > 700 && screenWidth < 900 ? '600px' : undefined,
                marginTop: isMobile
                  ? '20px'
                  : screenWidth > 700 && screenWidth < 900
                  ? '20px'
                  : '30px',
                padding: '10px',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0)',
                border: '2px solid #F3F4F6',
                borderRadius: '10px',
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: isMobile
                    ? 'column'
                    : screenWidth > 700 && screenWidth < 900
                    ? 'column'
                    : undefined,
                }}
              >
                <div style={{ display: isMobile ? 'flex' : undefined }}>
                  <img
                    src={doctorsData.profile_image}
                    alt="Doctor"
                    style={{
                      width: '200px',
                      height: '160px',
                      borderRadius: '10px',
                    }}
                  />
                </div>
                <div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      sx={{
                        fontWeight: '500',
                        color: '#2E3842',

                        fontSize: '25px',
                        fontStyle: 'normal',
                        lineHeight: '24px',
                        marginTop: isMobile
                          ? '10px'
                          : screenWidth > 700 && screenWidth < 900
                          ? '10px'
                          : undefined,
                        marginLeft: isMobile
                          ? '0px'
                          : screenWidth > 700 && screenWidth < 900
                          ? '0px'
                          : '20px',
                      }}
                    >
                      {`${doctorsData.firstname} ${doctorsData.middlename} ${doctorsData.lastname}`}
                    </Typography>
                    <Button
                      style={{
                        color: isBookmarked
                          ? themes.colors.primary
                          : themes.colors.lightGrey,

                        padding: '10px 20px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                      }}
                      onClick={handleBookmarkClick}
                    >
                      <BookmarkIcon />
                    </Button>

                    <Snackbar
                      open={openAlert}
                      autoHideDuration={5000}
                      onClose={handleAlertClose}
                      message="Added to favorites"
                      action={
                        <IconButton
                          size="small"
                          aria-label="close"
                          color="inherit"
                          onClick={handleAlertClose}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      }
                      style={{
                        position: 'fixed',
                        left: '50%',
                        top: '60%',
                        transform: 'translate(-40%, -30%)',
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: isMobile ? '10px' : '0px',
                      marginLeft: isMobile ? '0px' : '15px',
                    }}
                  >
                    {doctorsData.specializations.map(
                      (specialization, serviceIndex) => (
                        <Typography
                          sx={{
                            color: '#757575',
                            fontSize: '16px',
                            fontWeight: '400',
                            fontStyle: 'normal',
                            lineHight: '21px',
                            marginTop: '3px',
                            marginLeft: isMobile
                              ? '0px'
                              : screenWidth > 700 && screenWidth < 900
                              ? '0px'
                              : serviceIndex === 0
                              ? '5px'
                              : '0px',
                          }}
                        >
                          {serviceIndex === 0
                            ? capitalizeFirstLetter(specialization)
                            : `-${capitalizeFirstLetter(specialization)}`}
                        </Typography>
                      )
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: isMobile ? '10px' : '0px',
                    }}
                  >
                    <img
                      src={Logo}
                      style={{
                        marginTop: '17px',
                        marginLeft: isMobile
                          ? '0px'
                          : screenWidth > 700 && screenWidth < 900
                          ? '0px'
                          : '20px',
                      }}
                    />
                    {doctorsData.services.map((service, serviceIndex) => (
                      <Typography
                        sx={{
                          color: '#20C0F3',
                          fontSize: '16px',
                          fontWeight: '400',
                          fontStyle: 'normal',
                          lineHeight: '21px',
                          marginTop: '17px',
                          marginLeft: serviceIndex === 0 ? '5px' : '0px',
                        }}
                      >
                        {serviceIndex === 0
                          ? capitalizeFirstLetter(service)
                          : `-${capitalizeFirstLetter(service)}`}
                      </Typography>
                    ))}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: isMobile ? '10px' : '0px',
                    }}
                  >
                    <Rating
                      sx={{
                        marginLeft: isMobile
                          ? '0px'
                          : screenWidth > 700 && screenWidth < 900
                          ? '0px'
                          : '20px',
                        marginTop: '8px',
                      }}
                      name="half-rating-read"
                      value={doctorRatings[doctorsData._id]}
                      precision={0.5}
                      readOnly
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: isMobile ? '10px' : '3px',
                    }}
                  >
                    <LocationOnIcon
                      sx={{
                        marginLeft: isMobile
                          ? '0px'
                          : screenWidth > 700 && screenWidth < 900
                          ? '0px'
                          : '20px',
                      }}
                    />
                    <Typography
                      sx={{
                        color: '#757575',

                        fontStyle: 'normal',
                        lineHeight: '21px',
                        fontSize: '16px',
                        fontWeight: '400',
                        marginTop: '3px',
                      }}
                    >
                      {doctorsData.location_name
                        .split(', ')
                        .slice(0, 3)
                        .join(', ')}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginLeft: isMobile
                        ? '0px'
                        : screenWidth > 700 && screenWidth < 900
                        ? '0px'
                        : '16px',
                      marginTop: isMobile ? '10px' : '23.5px',
                    }}
                  >
                    {doctorsData.services.map((service, serviceIndex) => (
                      <Typography
                        key={serviceIndex}
                        style={{
                          display: 'flex',
                          padding: '4px 10.16px 4px 11px',
                          borderRadius: '4px',
                          border: '1px solid #CCC',
                          marginLeft: serviceIndex === 0 ? '0' : '11px',
                        }}
                      >
                        {capitalizeFirstLetter(service)}
                      </Typography>
                    ))}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: isMobile ? 'center' : undefined,
                    alignItems: isMobile ? 'center' : undefined,
                    marginLeft: isMobile
                      ? '0px'
                      : screenWidth > 700 && screenWidth < 900
                      ? undefined
                      : '220px',
                    marginTop: isMobile ? '20px' : '40px',
                  }}
                >
                  <button
                    onClick={() =>
                      handleViewProfileClick(
                        doctorsData,
                        doctorRatings[doctorsData._id]
                      )
                    }
                    style={{
                      width: '200px',
                      height: '45px',
                      border: '2px solid #20C0F3',
                      borderRadius: '4px',
                      color: '#20C0F3',
                      backgroundColor: '#fff',
                      display: 'flex',
                      padding: '12px 53.9px 12px 54.1px',
                      marginBottom: isMobile ? '10px' : '0px',
                    }}
                  >
                    View Profile
                  </button>

                  <button
                    onClick={() => handleBookAppointmentClick(doctorsData)}
                    style={{
                      marginTop: '15px',
                      width: '200px',
                      height: '45px',
                      border: '2px solid #20C0F3',
                      color: '#fff',
                      borderRadius: '4px',
                      backgroundColor: '#20C0F3',
                      display: 'flex',
                      padding: '10px',
                      justifyContent: 'center',
                    }}
                  >
                    Book Appointment
                  </button>
                </div>
              </CardContent>
            </Card>
          ))}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
              marginBottom: '20px',
            }}
          >
            {filteredDoctors.length > 0 && (
              <Pagination
                count={Math.ceil(filteredDoctors.length / doctorsPerPage)}
                page={page}
                onChange={(event, value) => setPage(value)}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '10px',
                }}
                classes={{
                  page: 'custom-page',
                  selectIcon: 'custom-arrow',
                }}
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    style={
                      item.type === 'page'
                        ? {
                            width: '50px',
                            height: '50px',
                            margin: '5px',
                            padding: '8px',
                            border: '1px solid #ccc',
                            cursor: 'pointer',
                            borderRadius: '10%',
                            transition: 'background-color 0.3s',
                            backgroundColor:
                              item.page === page
                                ? theme.colors.Lightblue
                                : 'inherit',
                            color: item.page === page ? '#fff' : 'inherit',
                          }
                        : {
                            width: '50px',
                            height: '50px',
                            margin: '5px',
                            padding: '8px',
                            border: '1px solid #ccc',
                            borderRadius: '10%',
                            transition: 'background-color 0.3s',
                          }
                    }
                  />
                )}
              />
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default SearchDoctor
