import React, { useState } from 'react'

import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import './style.css'
import { Link } from 'react-router-dom'

import '../../App.css'
import logo from '../../assets/images/logo.png'
import Footer from '../HomeFooter'
import LoginBanner from '../../assets/images/login-banner.svg'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { Navbar, Nav, Button } from 'react-bootstrap'
import Divider from '@mui/material/Divider'
import GoogleIcon from '../../assets/images/icons/google.svg'
import { login } from '../../api/common'
import { Typography } from '@mui/material'
const Login = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [doctor, setUser] = useState(null)
  const [token, setToken] = useState(null)
  const [loginError, setLoginError] = useState('')
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedOption, setSelectedOption] = useState('As Doctor')
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  const handleDialogOpen = () => {
    setOpenDialog(true)
  }

  const handleDialogClose = () => {
    setOpenDialog(false)
  }

  const handleRegistration = (selectedOption) => {
    // Redirect to the registration page with the selected user type as a parameter
    window.location.href = `/Register?userType=${selectedOption}`
  }

  const handleLogin = async (event) => {
    event.preventDefault()
    try {
      // Calling API function written in common.js
      const response = await login(username, password)
      if (response.data.success) {
        const userRole = response.data.user.role
        setUser(response.data.user)
        const token = response.data.token
        setToken(token)
        localStorage.setItem('token', token)
        if (userRole === 'doctor') {
          const doctor = response.data.doctor
          localStorage.setItem('doctor', JSON.stringify(doctor))
          window.location.href = '/Dashboard'
        } else if (userRole === 'patient') {
          const patient = response.data.patient
          localStorage.setItem('patient', JSON.stringify(patient))
          window.location.href = '/PatientDashboard'
        }
        console.log('Login successful:', response.data)
      } else {
        setLoginError(response.data.message)
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setLoginError(
          error.response.data.message || 'An error occurred during login.'
        )
      } else {
        setLoginError('An error occurred during login.')
      }
    }
  }

  return (
    <div>
      {/* Header */}
      <Navbar fixed="top" expand="lg" className="navbar">
        <Navbar.Brand href="/">
          <img
            src={logo}
            height={50}
            alt="Logo"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link href="/" className="menu-option">
              Home
            </Nav.Link>
            <Nav.Link href="/Dashboard" className="menu-option">
              Doctor Dashboard
            </Nav.Link>
            <Nav.Link href="/PatientDashboard" className="menu-option">
              Patient Dashboard
            </Nav.Link>
          </Nav>
          {/* <Button variant="primary" href='/Login' style={{backgroundColor:'#15558d',borderColor:'#15558d'}}>Login / Signup</Button> */}
        </Navbar.Collapse>
      </Navbar>
      {/* Header */}

      {/* Body Content */}
      <div>
        <div class="container-fluid">
          <div>
            <div className="div-row">
              <div className="div-col-md">
                <img
                  className="login-banner-png"
                  alt="Login banner png"
                  height="500px"
                  src={LoginBanner}
                />
              </div>
              <div className="div">
                <div className="d-flex justify-content-center div-login-header flex-column align-items-center">
                  <h1 className="font-weight-bold heading-forgot">
                    Welcome to{' '}
                  </h1>
                  <div>
                    {' '}
                    <img src={logo} height={50} alt="Logo" className=" " />{' '}
                  </div>
                </div>

                <form className="login-form" onSubmit={handleLogin}>
                  {loginError && <p className="error-message">{loginError}</p>}

                  <Grid item xs={12}>
                    <TextField
                      required
                      onChange={(e) => setUsername(e.target.value)}
                      id="username"
                      name="username"
                      label="Username/Email"
                      fullWidth
                      autoComplete="given-name"
                      placeholder="john_doe  /  patient@hexapi.tech"
                      className="my-3 font-weight-bold"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      onChange={(e) => setPassword(e.target.value)}
                      id="password"
                      name="password"
                      label="Password"
                      type={showPassword ? 'text' : 'password'}
                      fullWidth
                      placeholder="John_123   /  Sami_123"
                      autoComplete="new-password"
                      className="my-3 "
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {loginError && (
                      <Typography
                        style={{
                          color: 'grey',
                          fontSize: '13px',
                          marginLeft: '80%',
                        }}
                      >
                        <Link to="/ForgetPassword">Forget Password?</Link>
                      </Typography>
                    )}
                  </Grid>

                  <Button
                    type="submit"
                    style={{
                      backgroundColor: '#15558d',
                      borderColor: '#15558d',
                    }}
                    className="button mt-3"
                  >
                    <div className="text-wrapper-2">Login</div>
                  </Button>
                </form>
                <div className="d-flex flex-row align-items-center">
                  <div className="text-wrapper-3 text-right">
                    <Divider
                      sx={{ width: '100%', borderTop: '1px solid grey' }}
                    >
                      or
                    </Divider>
                  </div>
                </div>
                <Button
                  style={{
                    backgroundColor: '#ffffff',
                    borderColor: '#15558d',
                    color: '#15558d',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  className="button mt-3"
                >
                  <div className="text-wrapper-2" style={{ fontWeight: 600 }}>
                    <img
                      src={GoogleIcon}
                      alt="Google"
                      width="24"
                      height="24"
                      style={{ marginRight: '8px' }}
                    />
                    Continue with Google
                  </div>
                </Button>

                <div className="d-flex flex-row align-items-center ">
                  <div className="text-wrapper-3 text-right ">
                    Is it your first time?
                  </div>
                  <div className="button2">
                    <a href="#" onClick={handleDialogOpen}>
                      <div className="text-wrapper-3 text-left pl-2 ">
                        Create Account
                      </div>
                    </a>{' '}
                    <Dialog open={openDialog} onClose={handleDialogClose}>
                      <DialogContent>
                        <Select
                          value={selectedOption}
                          onChange={(e) => setSelectedOption(e.target.value)}
                          fullWidth
                          variant="outlined"
                          label="Account Type"
                          defaultOpen
                        >
                          <MenuItem value="doctor">As Doctor</MenuItem>
                          <MenuItem value="patient">As Patient</MenuItem>
                        </Select>
                        <Button
                          onClick={() => {
                            // Handle the selected option here
                            if (selectedOption === 'doctor') {
                              // Redirect to doctor registration page or perform other actions
                              window.location.href = '/Register?userType=doctor'
                            } else if (selectedOption === 'patient') {
                              // Redirect to patient registration page or perform other actions
                              window.location.href =
                                '/Register?userType=patient'
                            }
                          }}
                          style={{
                            backgroundColor: '#15558d',
                            color: '#fff',
                            marginTop: '16px',
                          }}
                          fullWidth
                        >
                          Create Account
                        </Button>
                      </DialogContent>
                    </Dialog>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
      {/* Footer */}
    </div>
  )
}

export default Login
