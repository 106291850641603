import React, { useState, useEffect } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Avatar from '@mui/material/Avatar'
import { Link } from 'react-router-dom'
import { logout } from '../../../utils/logout'
import LogoutIcon from '@mui/icons-material/Logout'
import LogoImage from '../../../assets/images/logo.png'
import Profilelogo from '../../../assets/images/doctors/doctor-02.jpg'
import i18next from 'i18next'
import cookies from 'js-cookie'
import us from '../../../assets/images/features/us.svg'
import lb from '../../../assets/images/features/lb.svg'

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  // lang
  const languages = [
    {
      code: 'en',
      name: 'English',
      country_code: us,
    },
    {
      code: 'ar',
      name: 'العربية',
      country_code: lb,
      dir: 'rtl',
    },
  ]

  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)

  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr'
  }, [currentLanguage])

  const handleLanguageChange = (languageCode) => {
    i18next.changeLanguage(languageCode)
    window.location.reload()
  }

  const handleOptionsClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    logout()
  }

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar sx={{ backgroundColor: 'white', height: '75px' }}>
        <Toolbar>
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <img
              src={LogoImage}
              alt="Logo"
              style={{
                height: '50px',
                marginTop: '7px',
              }}
            />
          </Link>
          <div style={{ flex: 1 }} />
          <IconButton
            onClick={handleOptionsClick}
            sx={{
              marginRight: '16px',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            disableTouchRipple
            disableRipple
          >
            <Avatar
              alt="User Avatar"
              src={Profilelogo}
              sx={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                marginTop: '5px',
              }}
            />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem component={Link} to="/Dashboard" onClick={handleClose}>
              Dashboard
            </MenuItem>
            <MenuItem
              component={Link}
              to="/ProfileSettings"
              onClick={handleClose}
            >
              Profile Settings
            </MenuItem>
            <MenuItem
              component={Link}
              to="/ChangePassword"
              onClick={handleClose}
            >
              Change Password
            </MenuItem>
            <MenuItem onClick={handleLogout} style={{ color: 'red' }}>
              <LogoutIcon sx={{ marginRight: '8px' }} />
              Logout
            </MenuItem>
            {/*}
            {languages.map(({ code, name, country_code }) => (
              <MenuItem
                key={country_code}
                onClick={() => handleLanguageChange(code)}
              >
                <img
                  width={20}
                  height={20}
                  src={country_code}
                  className="mx-2"
                />
                {name}
              </MenuItem>
            ))}
            */}
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default Header
