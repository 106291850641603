import axios from '../api/axios'
import { getToken } from '../utils/auth'
import '../utils/patient_info'
import Patient from '../utils/patient_info'

export async function fetchAppointment() {
  try {
    const token = getToken
    const pat = new Patient()
    const patientId = pat.id
    const response = await axios.get(
      `/api/patients/${patientId}/appointments`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data.appointments
  } catch (error) {
    throw error
  }
}

export const fetchReviews = async () => {
  const token = getToken
  //Keep it static till we get the doctor id via props
  const DoctorId = '65254c1a38ac5de3447a4e37'

  try {
    const response = await axios.get(`/api/reviews/doctor/${DoctorId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data.reviews
  } catch (error) {
    console.error('Error fetching reviews:', error)
    throw error
  }
}

export async function fetchTimeSlots(doctorID, selectedDate) {
  try {
    const token = getToken
    const response = await axios.get(
      `/api/doctor_time_slots/doctor/${doctorID}/day/${selectedDate}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data.timeSlots
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

export async function addAppointment(data, timeSlotID) {
  try {
    const token = getToken
    const pat = new Patient()
    const patientId = pat.id
    const response = await axios.post(
      `/api/patients/${patientId}/appointments/book/time_slots/${timeSlotID}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data
  } catch (error) {
    console.error('Error adding time template:', error)
    throw error
  }
}

export async function getDoctorRating(doctorID) {
  try {
    const token = getToken
    const response = await axios.get(
      `/api/reviews/doctor/${doctorID}/average`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data.averageRatings
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

export const postReview = async (reviewContent) => {
  const patient = new Patient()
  const PatientId = patient.id
  const docId = '65254c1a38ac5de3447a4e37'
  const token = getToken
  //localStorage.getItem('patient')

  try {
    const response = await axios.post(
      `/api/reviews/doctor/${docId}/patient/${PatientId}/write`,
      {
        rating: reviewContent.rating,
        review_text: reviewContent.review_text,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    const storedReviews = JSON.parse(localStorage.getItem('reviews')) || []
    storedReviews.push(response.data.review)
    localStorage.setItem('reviews', JSON.stringify(storedReviews))

    return response
  } catch (error) {
    console.error('Error posting this review:', error)
    return error
  }
}
export async function registerPatient(personalInfo, emailInfo) {
  try {
    const response = await axios.post('/api/patients/register', {
      firstname: personalInfo[0]['firstname'],
      middlename: personalInfo[0]['middlename'],
      lastname: personalInfo[0]['lastname'],
      gender: personalInfo[0]['gender'],
      email: emailInfo[0]['email'],
      phone: emailInfo[0]['phoneNumber'],
      governorate: personalInfo[0]['location'],
      blood_group: personalInfo[0]['blood_group'],
    })
    if (!response.data.success) {
      window.alert('An error occurred during Register.')
      return
    }
  } catch (error) {
    console.log(error)
    if (error.response && error.response.data) {
      window.alert(
        error.response.data.message ||
          'An error occurred during Register.'
      )
    } else {
      window.alert('An error occurred during Register.')
    }
    return
  }
}
