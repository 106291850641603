import React from 'react'
import { Container, Row, Col, ListGroup, Button } from 'react-bootstrap'
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaAngleDoubleRight,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
  FaGlobe,
} from 'react-icons/fa'
import { BsTwitterX } from 'react-icons/bs'
import logo from '../assets/images/footer-logo.png' // You may need to adjust this import to your actual logo path

const Footer = () => {
  return (
    <footer className="footer  py-4  custom-footer">
      <Container>
        <Row>
          <Col xs={12} md={3} className="mb-4 mb-md-0 ">
            <img src={logo} height={50} alt="Logo" className="mb-2" />
            <p>
              Tabiby is an online platform to connect Lebanese patients with
              their doctors and to book appointments with them easily.
            </p>
            <div className="d-flex">
              {/* You can wrap these in <a> tags as per your previous code */}
              <a target="#" href="https://www.facebook.com/HexaPiTech">
                {' '}
                <FaFacebookF className="mr-3" />
              </a>
              <a target="#" href="https://www.twitter.com/HexaPiTech">
                <BsTwitterX className="mr-3" />
              </a>
              <a
                target="#"
                href="https://www.linkedin.com/company/hexapi-tech/"
              >
                <FaLinkedinIn className="mr-3" />
              </a>
              <a target="#" href="https://www.instagram.com/hexa.pi">
                <FaInstagram className="mr-3" />
              </a>
              <a target="#" href="https://hexapi.tech">
                <FaGlobe />
              </a>
            </div>
          </Col>
          <Col xs={12} md={3}>
            <h5 className="text-uppercase footer-title">For Doctors</h5>
            <ListGroup>
              <ListGroup.Item className=" border-0 px-0 py-1" id="bg">
                <a href="/Login">
                  <FaAngleDoubleRight color="white" /> Login/Register{' '}
                </a>
              </ListGroup.Item>
              <ListGroup.Item className=" border-0 px-0 py-1" id="bg">
                <a href="/Dashboard">
                  <FaAngleDoubleRight color="white" /> Doctor Dashboard
                </a>
              </ListGroup.Item>

              <ListGroup.Item className=" border-0 px-0 py-1" id="bg">
                <a href="/DoctorCalendar">
                  <FaAngleDoubleRight color="white" /> Doctor Calendar
                </a>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col xs={12} md={3}>
            <h5 className="text-uppercase footer-title">For Patients</h5>
            <ListGroup className="text-white">
              <ListGroup.Item className=" border-0 px-0 py-1" id="bg">
                <a href="/Login">
                  <FaAngleDoubleRight color="white" /> Login/Register
                </a>
              </ListGroup.Item>
              <ListGroup.Item className=" border-0 px-0 py-1" id="bg">
                <a href="/PatientDashboard">
                  <FaAngleDoubleRight color="white" /> Patient Dashboard
                </a>
              </ListGroup.Item>
              <ListGroup.Item className=" border-0 px-0 py-1" id="bg">
                <a href="/SearchDoctor">
                  <FaAngleDoubleRight color="white" /> Search for Doctors
                </a>
              </ListGroup.Item>
              <ListGroup.Item className=" border-0 px-0 py-1" id="bg">
                <a href="/PatientBooking">
                  <FaAngleDoubleRight color="white" /> Booking Appointment
                </a>
              </ListGroup.Item>
            </ListGroup>
          </Col>

          <Col xs={12} md={3}>
            <h5 className="text-uppercase footer-title">Contact Us</h5>
            <ListGroup>
              <ListGroup.Item className=" border-0 px-0 py-1" id="bg">
                <FaMapMarkerAlt className="mr-2" />
                HexaPi Tech, Lebanon
              </ListGroup.Item>
              <ListGroup.Item className="border-0 px-0 py-1" id="bg">
                <FaPhoneAlt className="mr-2" />
                +961-03644705
              </ListGroup.Item>
              <ListGroup.Item className="border-0 px-0 py-1" id="bg">
                <FaEnvelope className="mr-2" />
                info@hexapi.tech
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
