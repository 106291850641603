const theme = {
  colors: {
    primary: '#15558D', //blue
    secondary: '#87CEEB', // sky blue
    background: '#F0F0F0', //light-grey background
    hover: '#757575', //dark grey
    borderColor: '#E2E5E8', //light grey
    Lightblue: '#20C0F3',
    lightGrey: '#E2E5E8',
    rating: '#F4C150',
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
  },
}
export default theme
