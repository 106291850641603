import React from 'react'
import { Navbar, Nav, Button } from 'react-bootstrap'
import logo from '../../assets/images/logo.png'
import styled from '@emotion/styled'
const StyledNavLink = styled(Nav.Link)({
  color: 'black',
  fontweight: 'bold',
})

const HomeHeader = () => {
  return (
    <Navbar
      fixed="top"
      expand="lg"
      className="navbar-home"
      style={{ backgroundColor: '#E1F3FFff' }}
    >
      <Navbar.Brand href="/">
        <img
          src={logo}
          height={50}
          alt="Logo"
          className="d-inline-block align-top"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto header-nav">
          <StyledNavLink href="/Dashboard">Doctor Dashboard</StyledNavLink>
          <StyledNavLink href="/PatientDashboard">
            Patient Dashboard
          </StyledNavLink>
        </Nav>
        <Button
          variant="primary"
          href="/Login"
          style={{ backgroundColor: '#15558d', borderColor: '#15558d' }}
        >
          Login / Signup
        </Button>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default HomeHeader
