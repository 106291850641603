import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'

export default function PaymentForm(props) {
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const location = useLocation()
  const userType = new URLSearchParams(location.search).get('userType')

  function handleChange(event) {
    event.preventDefault()
    if (event.target.id === 'email') {
      setEmail(event.target.value)
      props.sendEmailInfo({
        email: event.target.value,
        phoneNumber: phoneNumber, // Use the existing phone number value
      })
    } else if (event.target.id === 'phoneNumber') {
      setPhoneNumber(event.target.value)
      props.sendEmailInfo({
        email: email, // Use the existing email value
        phoneNumber: event.target.value,
      })
    }
  }

  return (
    <React.Fragment>
      <Typography variant="h9" gutterBottom>
        {userType === 'patient'
          ? 'Provide us with your email address to contact you. After clicking Next, a code will be sent to this email to verify your account.'
          : 'Provide us with your phone number to contact you. After clicking Next, a code will be sent to this number to verify your account.'}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="email"
            label="E-mail (Required)"
            fullWidth
            variant="standard"
            onChange={handleChange}
          />
          <TextField
            required={userType !== 'patient'}
            id="phoneNumber"
            label={
              userType === 'patient'
                ? 'Phone Number (Optional)'
                : 'Phone Number (Required)'
            }
            fullWidth
            type="number"
            onChange={handleChange}
            variant="standard"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
