import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Form, Col } from 'react-bootstrap'
import { FaRegCompass, FaUserCheck } from 'react-icons/fa'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

export default function GeneralInfo(props) {
  const [title, setTitle] = useState(
    props.userType === 'patient' ? 'Patient' : 'Dr.'
  )
  const [gender, setGender] = useState('male')
  const [bloodgroup, setbloodgroup] = useState('A+')
  const [firstName, setFirstName] = useState('')
  const [middleName, setMiddleName] = useState('')
  const [lastName, setLastName] = useState('')
  const [address, setaddress] = useState('')
  const [userLocation, setUserLocation] = useState(null)

  const location = useLocation()
  const userType = new URLSearchParams(location.search).get('userType')

  // Function to fetch user's location
  const fetchUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords
        const userCoordinates = `${latitude}, ${longitude}`
        setaddress(userCoordinates) // Update the address with coordinates
        setUserLocation(userCoordinates)
      })
    } else {
      console.error('Geolocation is not supported by this browser.')
    }
  }

  // Handle TextField click event for doctor's address
  const handleAddressClick = () => {
    if (userType === 'doctor') {
      fetchUserLocation()
    }
  }

  function handleChange(event) {
    event.preventDefault()
    if (event.target.id == 'firstName') {
      setFirstName(event.target.value)
    } else if (event.target.id == 'middleName') {
      setMiddleName(event.target.value)
    } else if (event.target.id == 'lastName') {
      setLastName(event.target.value)
    } else if (event.target.id == 'address') {
      setaddress(event.target.value)
    } else if (event.target.id == 'title') {
      setTitle(event.target.value)
    } else if (event.target.id == 'gender') {
      setGender(event.target.value)
    } else if (event.target.id === 'blood_group') {
      setbloodgroup(event.target.value)
    }
  }
  props.sendPersonalInfo({
    title: title,
    gender: gender,
    firstname: firstName,
    middlename: middleName,
    lastname: lastName,
    location: userLocation,
    blood_group: bloodgroup,
  })

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        {userType === 'doctor' && (
          <Grid item xs={12} sm={6}>
            <Select
              required
              labelId="demo-simple-select-label"
              id="title"
              value={title}
              label="Title"
              onChange={handleChange}
              sx={{ width: '110px' }}
            >
              <MenuItem value={'Dr.'}>Dr.</MenuItem>
              <MenuItem value={'Med.'}>Med.</MenuItem>
              <MenuItem value={'Prof.'}>Prof.</MenuItem>
            </Select>
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <Select
            required
            labelId="gender"
            id="gender"
            value={gender}
            label="Gender"
            onChange={(e) => setGender(e.target.value)}
          >
            {' '}
            <MenuItem value={'male'}>Male</MenuItem>
            <MenuItem value={'female'}>Female</MenuItem>
          </Select>
        </Grid>

        {userType === 'patient' && (
          <Grid item xs={12} sm={6}>
            <Select
              required
              onChange={(e) => setbloodgroup(e.target.value)}
              labelId="demo-simple-select-label"
              id="blood_group"
              value={bloodgroup}
              label="Blood Group"
            >
              <MenuItem value="A+">A+</MenuItem>
              <MenuItem value="B+">B+</MenuItem>
              <MenuItem value="AB+">AB+</MenuItem>
              <MenuItem value="O+">O+</MenuItem>
              <MenuItem value="A-">A-</MenuItem>
              <MenuItem value="B-">B-</MenuItem>
              <MenuItem value="AB-">AB-</MenuItem>
              <MenuItem value="O-">O-</MenuItem>
            </Select>
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <TextField
            required
            onChange={handleChange}
            id="firstName"
            name="firstName"
            label="First name"
            fullWidth
            autoComplete="given-name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            onChange={handleChange}
            id="middleName"
            name="middleName"
            label="Middle name"
            fullWidth
            autoComplete="middle-name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            onChange={handleChange}
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            autoComplete="family-name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {userType === 'patient' ? (
            <Form.Group as={Col} controlId="location">
              <Form.Label>
                <FaRegCompass className="icon1" /> Governate
              </Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => setUserLocation(e.target.value)}
              >
                <option>Beirut</option>
                <option>Saida (Sidon)</option>
                <option>Nabatieh</option>
                <option>Tyre (Sour)</option>
                <option>Bekaa (Bekkaa)</option>
                <option>Zahle</option>
                <option>Byblos (Jbeil)</option>
                <option>Tripoli (Trablus)</option>
                <option>Aley</option>
                <option>Baalbek</option>
                <option>Jounieh</option>
                <option>Chouf</option>
                <option>Mount Lebanon</option>
                <option>Akkar</option>
                <option>Keserwan</option>
                <option>Metn</option>
                <option>Zgharta</option>
                <option>Bint Jbeil</option>
                <option>Hasbaya</option>
                <option>Rachaya</option>
              </Form.Control>
            </Form.Group>
          ) : (
            <TextField
              required
              onChange={handleChange}
              id="address"
              name="clinicAddress"
              label="Clinic Address"
              fullWidth
              variant="standard"
              onClick={handleAddressClick}
              value={userLocation || address}
            />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
