import Header from '../../Doctors/Header'
import Footer from '../../Footer'
import React, { useState } from 'react'
function ForgetPassword() {
  const [email, setEmail] = useState('')

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handleResetPassword = (e) => {
    e.preventDefault()
  }

  return (
    <div>
      <Header />

      <div class="breadcrumb-bar">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-12 col-12">
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  {/* You should change the breadcrumb Title */}
                  <li class="breadcrumb-item active" aria-current="page">
                    Forget Password
                  </li>
                </ol>
              </nav>
              {/* You should change the breadcrumb Title */}
              <h2 class="breadcrumb-title">Forget Password</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="div-row" style={{ minHeight: '80vh' }}>
        <div
          className="div"
          style={{
            border: '1px solid #f0f0f0',
            borderRadius: '4px',
            flexDirection: 'column',
            gap: '20px',
            padding: '25px',
          }}
        >
          <h2 style={{ color: '#15558d', textAlign: 'center  ' }}>
            Forget Password
          </h2>
          <form onSubmit={handleResetPassword} style={{ width: '100%' }}>
            <div>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={handleEmailChange}
                required
                placeholder="Email"
                style={{
                  width: '100%',
                  marginBottom: '15px',
                  height: '40px',
                }}
              />
            </div>
            <button
              type="submit"
              style={{
                justifyContent: 'center',
                width: '100%',
                padding: '9px 0',
                textAlign: 'center',
                background: '#15558d',
                color: '#fff',
              }}
            >
              Reset Password
            </button>
          </form>
          <p style={{ textAlign: 'center', marginTop: '10px' }}>
            <div>
              <a
                href="/login"
                style={{
                  display: 'inline-block',
                  cursor: 'pointer',
                  textDecoration: 'none',
                  color: '#15558d',
                  textDecoration: 'none',
                }}
              >
                <span
                  style={{
                    display: 'inline-block',
                    width: '10px',
                    height: '10px',
                    border: 'solid #15558d', // Use your desired color
                    borderWidth: '0 2px 2px 0',
                    transform: 'rotate(135deg)',
                    marginRight: '5px', // Adjust the spacing between the arrow and text
                  }}
                ></span>{' '}
                Go back to login
              </a>
            </div>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ForgetPassword
