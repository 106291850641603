class Doctor {
  id
  user_id
  firstname
  lastname
  username
  photo
  speciality
  title
  email
  middlename
  awards
  gallery
  businessHours
  consultationFee
  gender
  languages
  location
  qualifications
  services
  socialMedia
  startingYear

  constructor() {
    let user

    try {
      user = JSON.parse(localStorage.getItem('doctor'))
      this.username = user.username; 
      this.user_id = user.user_id
      this.id = user._id
      this.firstname = user.firstname
      this.lastname = user.lastname
      this.photo = user.profile_image
      this.speciality = user.specializations
      this.title = user.title
      this.email = user.email
      this.middlename = user.middlename
      this.awards = user.awards
      this.gallery = user.gallery
      this.businessHours = user.business_hours
      this.consultationFee = user.consultation_fee
      this.gender = user.gender
      this.languages = user.languages_spoken
      this.location = user.location
      this.qualifications = user.qualifications
      this.services = user.services
      this.socialMedia = user.social_media_links
      this.startingYear = user.starting_year
    } catch (error) {
      console.error('Error parsing user data from local storage:', error)
    }
  }

  updateAndSaveToLocalStorage() {
    try {
      const doctorData = {
        username:this.username,
    firstname: this.firstname,
    middlename: this.middlename,
    lastname: this.lastname,
    phone: this.phone,
    gender: this.gender,
    consultation_fee: this.consultation_fee,
    profile_image: this.profile_image,
    about: this.about,
    location_name: this.location_name,
    qualifications: this.qualifications,
    experience: this.experience,
    awards: this.awards,
    social_media_links: this.social_media_links,
    specializations: this.specializations,
    services: this.services,
    gallery: this.gallery,
    date_of_birth: this.date_of_birth,
      };

      localStorage.setItem('doctor', JSON.stringify(doctorData));
    } catch (error) {
      console.error('Error saving doctor data to local storage:', error);
    }
  }


}

export default Doctor
