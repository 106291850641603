import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom' // Import NavLink
import './style.css'
import {
  FaColumns,
  FaCalendar,
  FaBookmark,
  FaClinicMedical,
  FaSearch,
  FaFileMedical,
} from 'react-icons/fa'

function PatientSidebar() {
  const [activePage, setActivePage] = useState('/Dashboard')

  const handleNavigation = page => {
    setActivePage(page)
  }
  useEffect(() => {
    handleNavigation(window.location.pathname)
    console.log('activePage updated:', activePage)
  }, [activePage])

  return (
    <div class="profile-sidebar">
      <div class="widget-profile pro-widget-content">
        <div class="profile-info-widget">
          <a href="#" class="booking-doc-img">
            <img
              src={require('../../../assets/images/patients/patient.jpg')}
              alt="Patient"
            />
          </a>
          <div class="profile-det-info">
            <h3 style={{ fontWeight: 'bolder', fontSize: 24 }}>
              {'Patient Test'}
            </h3>

            <div class="patient-details">
              <h6 class="mb-0">{'Patient ID: PT3974'}</h6>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-widget">
        <nav className="dashboard-menu">
          <ul>
            <li className={activePage === '/PatientDashboard' ? 'active' : ''}>
              <NavLink to="/PatientDashboard">
                <FaColumns />
                <span> &nbsp;&nbsp;Dashboard</span>
              </NavLink>
            </li>
            <li className={activePage === '/SearchDoctor' ? 'active' : ''}>
              <NavLink to="/SearchDoctor">
                <FaSearch />
                <span> &nbsp;&nbsp;Search Doctor</span>
              </NavLink>
            </li>
            <li className={activePage === '/AllDoctors' ? 'active' : ''}>
              <NavLink to="/AllDoctors">
                <FaClinicMedical />
                <span> &nbsp;&nbsp;All Doctors</span>
              </NavLink>
            </li>
            <li className={activePage === '/Favourites' ? 'active' : ''}>
              <NavLink to="/Favourites">
                <FaBookmark />
                <span> &nbsp;&nbsp;Favourites</span>
              </NavLink>
            </li>
            <li className={activePage === '/PatientCalendar' ? 'active' : ''}>
              <NavLink to="/PatientCalendar">
                <FaCalendar />
                <span> &nbsp;&nbsp;Calendar</span>
              </NavLink>
            </li>
            <li className={activePage === '/MedicalRecords' ? 'active' : ''}>
              <NavLink to="/MedicalRecords">
                <FaFileMedical />
                <span> &nbsp;&nbsp;Medical Records</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default PatientSidebar
