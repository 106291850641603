import * as React from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

export default function Review(props) {
  function handleChange(event) {
    event.preventDefault()

    if (event.target.id === 'code') {
      props.updateOTPCode(event.target.value)
    }
  }
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Verify Your Email
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <input
            type="text"
            required
            id="code"
            name="code"
            onChange={handleChange}
            value={props.code}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
