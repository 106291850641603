import React from 'react'
import { useState, useEffect } from 'react'
import {
  CardContent,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'
import Sidebar from '../SideBar'
import '../../../App.css'
import Header from '../Header'
import Footer from '../../Footer'
import CalendarIcon from '../../../assets/images/icons/calendar.svg'
import CustomIcon2 from '../../../assets/images/icons/cup2.svg'
import { FaPlus } from 'react-icons/fa'
import { FaMinus, FaEdit } from 'react-icons/fa'
import { Button, Card, Row, Col } from 'react-bootstrap'
import { fetchTimeTemplates } from '../../../api/doctor'
import { addTimeTemplate } from '../../../api/doctor'
import { updateTimeTemplate } from '../../../api/doctor'
const ScheduleTimings = () => {
  const [timeTemplates, setTimeTemplates] = useState([])

  useEffect(() => {
    async function fetchData() {
      try {
        const timeTemplatesData = await fetchTimeTemplates()

        setTimeTemplates(timeTemplatesData)
      } catch (error) {
        console.log('Error fetching Time Template', error)
      }
    }

    fetchData()
  }, [])

  const weekdays = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']
  const [openDialog, setOpenDialog] = useState(false)
  const [successMessageVisible, setSuccessMessageVisible] = useState(false)
  const [activeDay, setActiveDay] = useState('MON')

  const handleDayClick = (day) => {
    setActiveDay(day)
  }

  const handleOpenDialog = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const [inputFields, setInputFields] = useState([
    { startTime: '', endTime: '' },
  ])

  const handleAddMore = () => {
    setInputFields([...inputFields, { startTime: '', endTime: '' }])
  }

  const deleteInput = (index) => {
    const updatedFields = [...inputFields]
    updatedFields.splice(index, 1)
    setInputFields(updatedFields)
  }

  const dayOfWeekMap = {
    MON: 1,
    TUE: 2,
    WED: 3,
    THU: 4,
    FRI: 5,
    SAT: 6,
    SUN: 7,
  }

  const selectedDayTemplate = timeTemplates.find(
    (template) => template.day_of_week === dayOfWeekMap[activeDay]
  )

  const appointmentDuration =
    selectedDayTemplate && selectedDayTemplate.slot_duration
      ? `${selectedDayTemplate.slot_duration} MINS`
      : '0 MINS'

  const breakDuration =
    selectedDayTemplate && selectedDayTemplate.break_duration
      ? `${selectedDayTemplate.break_duration} MINS`
      : '0 MINS'

  const startTime = selectedDayTemplate ? selectedDayTemplate.start_time : 'N/A'

  const endTime = selectedDayTemplate ? selectedDayTemplate.end_time : 'N/A'

  const [appointmentDurationInput, setAppointmentDuration] = useState('')
  const [breakDurationInput, setBreakDuration] = useState('')

  const refetchTimeTemplates = async () => {
    try {
      const timeTemplatesData = await fetchTimeTemplates()
      setTimeTemplates(timeTemplatesData)
    } catch (error) {
      console.log('Error re-fetching Time Templates', error)
    }
  }

  const handleAddtime = (event) => {
    event.preventDefault()

    const data = {
      breakDuration: breakDurationInput,
      dayOfWeek: dayOfWeekMap[activeDay],
      endTime: inputFields.map((field) => field.endTime),
      slotDuration: appointmentDurationInput,
      startTime: inputFields.map((field) => field.startTime),
    }

    const addTime = async () => {
      try {
        const response = await addTimeTemplate(data)
        console.log('Time template added successfully:', response)

        await refetchTimeTemplates()

        setSuccessMessageVisible(true)
        setInputFields([{ startTime: '', endTime: '' }])
        setBreakDuration('')
        setAppointmentDuration('')
      } catch (error) {
        console.error('Error adding time template:', error)
      }
    }

    addTime()
  }

  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false)
  const [selectedTimeTemplate, setSelectedTimeTemplate] = useState(null)

  const handleUpdateClick = (timeTemplate, start, end) => {
    setInputFields([{ startTime: start, endTime: end }])
    setSelectedTimeTemplate(timeTemplate)
    setIsUpdateDialogOpen(true)
  }

  const handleUpdateDialogClose = () => {
    setIsUpdateDialogOpen(false)
  }

  const updateTime = async () => {
    if (selectedTimeTemplate) {
      const data = {
        start_time: inputFields.map((field) => field.startTime),
        end_time: inputFields.map((field) => field.endTime),
      }
      try {
        const time_Template_ID = selectedTimeTemplate._id
        const response = await updateTimeTemplate(time_Template_ID, data)
        await refetchTimeTemplates()
        setIsUpdateDialogOpen(false)
        setInputFields([{ startTime: '', endTime: '' }])
      } catch (error) {
        console.error('Error updating time template:', error)
      }
    }
  }

  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div>
      <Header />

      <div class="breadcrumb-bar">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-12 col-12">
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/Dashboard">Home</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Opening Hours
                  </li>
                </ol>
              </nav>
              <h2 class="breadcrumb-title">Opening Hours</h2>
            </div>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="container-fluid">
          <div className="row">
            <div className="col-md-5 col-lg-4 col-xl-3 ">
              <Sidebar />
            </div>

            <div className="col-md-7 col-lg-8 col-xl-9">
              <Card
                style={{
                  maxWidth: 950,

                  borderRadius: '20px',
                }}
              >
                <Card.Body>
                  <Row>
                    <Col xs={12} sm={6}>
                      <div className="d-flex align-items-center">
                        <img
                          src={CalendarIcon}
                          alt="Icon"
                          style={{
                            width: '24px',
                            height: '24px',
                          }}
                        />
                        <span
                          style={{
                            marginLeft: '10px',
                            marginTop: '20px',
                            marginBottom: '25px',
                            color: '#15558D',
                            fontWeight: 'bold',
                          }}
                        >
                          Appointment Duration:
                        </span>
                        <span
                          style={{
                            marginLeft: '10px',
                            color: '#15558D',
                            fontWeight: 'bold',
                            marginBottom: '3px',
                          }}
                        >
                          {appointmentDuration}
                        </span>
                      </div>
                    </Col>
                    <Col xs={12} sm={6}>
                      <div className="d-flex align-items-center">
                        <img
                          src={CustomIcon2}
                          alt="Icon"
                          style={{
                            width: '24px',
                            height: '24px',
                            marginBottom: '4px',
                          }}
                        />
                        <span
                          style={{
                            marginLeft: '10px',
                            marginTop: '20px',
                            marginBottom: '25px',
                            color: '#15558D',
                            fontWeight: 'bold',
                          }}
                        >
                          Break Duration:
                        </span>
                        <span
                          style={{
                            marginLeft: '10px',
                            color: '#15558D',
                            fontWeight: 'bold',
                            marginBottom: '3px',
                          }}
                        >
                          {breakDuration}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Card
                style={{
                  maxWidth: '960px',
                  marginTop: '20px',
                  paddingBottom: '5%',
                  borderRadius: '20px',
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    style={{
                      marginBottom: '40px',
                      textAlign: 'center',
                      fontWeight: 'bold',
                      color: '#15558D',
                    }}
                  >
                    Availability
                  </Typography>

                  {screenWidth < 980 ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '20px',
                      }}
                    >
                      <select
                        onChange={(e) => handleDayClick(e.target.value)}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          padding: '10px 20px',
                          backgroundColor: '#15558D',
                          color: '#fff',
                          border: 'none',
                          borderRadius: '10px',
                          cursor: 'pointer',
                          fontWeight: 'bold',
                        }}
                      >
                        {weekdays.map((day, index) => (
                          <option key={index} value={day}>
                            {day}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        maxWidth: '600px',
                      }}
                    >
                      {weekdays.map((day, index) => (
                        <Button
                          key={index}
                          onClick={() => handleDayClick(day)}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginLeft: '20px',
                            width: '100%',
                            height: '40px',
                            padding: '10px',
                            color: '#fff',
                            fontWeight: 'bold',
                            borderStyle: 'none',
                            borderRadius: '10px',
                            background:
                              day === activeDay
                                ? 'linear-gradient(180deg, #0E62AA 0%, rgba(26, 116, 194, 0.42) 100%)'
                                : 'linear-gradient(180deg, #B1B2B3 0%, #D5D7D9 0.01%, rgba(84, 87, 90, 0.42) 100%)',
                          }}
                        >
                          {day}
                        </Button>
                      ))}
                    </div>
                  )}

                  {!selectedDayTemplate ? (
                    <div>
                      <Typography
                        variant="h5"
                        style={{
                          marginTop: '30px',
                          textAlign: 'center',
                          fontWeight: 'bold',
                          color: '#15558D',
                        }}
                      >
                        NO TIME SLOTS!
                      </Typography>
                      <Typography
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        <button
                          id="button2"
                          style={{
                            backgroundColor: '#15558D',
                            color: '#fff',
                            width: '250px',
                            padding: '10px 20px',
                            border: 'none',
                            borderRadius: '15px',
                            cursor: 'pointer',
                            marginTop: '10px',
                          }}
                          onClick={handleOpenDialog}
                        >
                          ADD THEM NOW!
                        </button>
                      </Typography>
                    </div>
                  ) : (
                    <div
                      id="time"
                      style={{
                        display: 'flex',
                        marginTop: '15px',
                        flexWrap: 'wrap',
                        marginLeft: screenWidth < 900 ? '150px' : '170px',
                      }}
                    >
                      {startTime.map((start, index) => (
                        <button
                          id="slot"
                          key={index}
                          onClick={() =>
                            handleUpdateClick(
                              selectedDayTemplate,
                              start,
                              endTime[index]
                            )
                          }
                          style={{
                            marginLeft: '0px',
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '6px 16px',
                            backgroundColor: '#15558D',
                            color: '#fff',
                            minWidth: '110px',
                            border: '1px',
                            borderRadius: '8px',
                            cursor: 'pointer',
                            flexBasis: 'calc(33.33% - 20px)',
                            margin: '10px',
                          }}
                        >
                          {`${start} - ${endTime[index]}`}
                          <FaEdit
                            style={{
                              marginLeft: '10px',
                              marginTop: '2px',
                              width: '20px',
                              height: '20px',
                            }}
                          />
                        </button>
                      ))}

                      <Dialog
                        open={isUpdateDialogOpen}
                        onClose={handleUpdateDialogClose}
                        aria-labelledby="update-dialog-title"
                      >
                        <DialogTitle
                          id="update-dialog-title"
                          variant="h5"
                          sx={{ color: 'rgb(21, 85, 141)' }}
                        >
                          Update Time Slots
                        </DialogTitle>
                        <DialogContent>
                          {inputFields.map((field, index) => (
                            <div
                              key={index}
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginTop: '20px',
                              }}
                            >
                              <div style={{ display: 'flex' }}>
                                <Typography
                                  variant="h6"
                                  sx={{ mt: '5px', color: 'rgb(21, 85, 141)' }}
                                >
                                  From:
                                </Typography>
                                <input
                                  type="time"
                                  value={field.startTime}
                                  onChange={(e) => {
                                    const updatedFields = [...inputFields]
                                    updatedFields[index].startTime =
                                      e.target.value
                                    setInputFields(updatedFields)
                                  }}
                                  fullWidth
                                  style={{
                                    marginLeft: '10px',
                                    width: '150px',
                                    border: '1px solid #15558D',
                                    borderRadius: '10px',
                                    padding: '10px',
                                  }}
                                />
                                <Typography
                                  variant="h6"
                                  sx={{
                                    mt: '5px',
                                    ml: '5px',
                                    color: 'rgb(21, 85, 141)',
                                  }}
                                >
                                  To:
                                </Typography>
                                <input
                                  type="time"
                                  value={field.endTime}
                                  onChange={(e) => {
                                    const updatedFields = [...inputFields]
                                    updatedFields[index].endTime =
                                      e.target.value
                                    setInputFields(updatedFields)
                                  }}
                                  fullWidth
                                  margin="normal"
                                  style={{
                                    marginLeft: '10px',
                                    width: '150px',
                                    border: '1px solid #15558D',
                                    borderRadius: '10px',
                                    padding: '10px',
                                  }}
                                />
                                {index === 0 && (
                                  <button
                                    onClick={handleAddMore}
                                    style={{
                                      border: 'none',
                                      background: '#fff',
                                    }}
                                  >
                                    <FaPlus
                                      style={{
                                        width: '20px',
                                        height: '20px',
                                        color: 'rgb(21, 85, 141)',
                                        marginBottom: '8px',
                                        marginLeft: '10px',
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </button>
                                )}
                                {index >= 1 && (
                                  <button
                                    onClick={() => deleteInput(index)}
                                    style={{
                                      border: 'none',
                                      background: '#fff',
                                    }}
                                  >
                                    <FaMinus
                                      style={{
                                        width: '20px',
                                        height: '20px',
                                        color: 'rgb(21, 85, 141)',
                                        marginBottom: '8px',
                                        marginLeft: '10px',
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </button>
                                )}
                              </div>
                            </div>
                          ))}
                        </DialogContent>
                        <DialogActions>
                          <button
                            onClick={handleUpdateDialogClose}
                            style={{
                              border: 'none',
                              padding: '10px',
                              borderRadius: '5px',
                              width: '80px',
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            onClick={updateTime}
                            style={{
                              border: 'none',
                              padding: '10px',
                              background: 'rgb(21, 85, 141)',
                              borderRadius: '5px',
                              width: '80px',
                              color: '#fff',
                            }}
                          >
                            Save
                          </button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  )}
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          variant="h5"
          sx={{ color: 'rgb(21, 85, 141)' }}
        >
          Add Time Slots
        </DialogTitle>
        <DialogContent>
          {inputFields.map((field, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '20px',
              }}
            >
              <Typography
                variant="h6"
                sx={{ mt: '5px', color: 'rgb(21, 85, 141)' }}
              >
                From:
              </Typography>
              <input
                type="time"
                placeholder="From:"
                value={field.startTime}
                onChange={(e) => {
                  const updatedFields = [...inputFields]
                  updatedFields[index].startTime = e.target.value
                  setInputFields(updatedFields)
                }}
                style={{
                  width: '150px',
                  marginLeft: '10px',
                  marginRight: '10px',
                  border: '1px solid #15558D',
                  borderRadius: '10px',
                  padding: '10px',
                }}
              />
              <Typography
                variant="h6"
                sx={{ mt: '5px', color: 'rgb(21, 85, 141)' }}
              >
                To:
              </Typography>
              <input
                type="time"
                placeholder="To:"
                value={field.endTime}
                onChange={(e) => {
                  const updatedFields = [...inputFields]
                  updatedFields[index].endTime = e.target.value
                  setInputFields(updatedFields)
                }}
                style={{
                  marginLeft: '10px',
                  width: '150px',
                  border: '1px solid #15558D',
                  borderRadius: '10px',
                  padding: '10px',
                }}
              />
              {index === 0 && (
                <button
                  onClick={handleAddMore}
                  style={{ border: 'none', background: '#fff' }}
                >
                  <FaPlus
                    style={{
                      width: '20px',
                      height: '20px',
                      color: 'rgb(21, 85, 141)',
                      marginBottom: '8px',
                      marginLeft: '10px',
                      cursor: 'pointer',
                    }}
                  />
                </button>
              )}
              {index >= 1 && (
                <button
                  onClick={() => deleteInput(index)}
                  style={{ border: 'none', background: '#fff' }}
                >
                  <FaMinus
                    style={{
                      width: '20px',
                      height: '20px',
                      color: 'rgb(21, 85, 141)',
                      marginBottom: '8px',
                      marginLeft: '10px',
                      cursor: 'pointer',
                    }}
                  />
                </button>
              )}
            </div>
          ))}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '30px',
              marginLeft: '50px',
            }}
          >
            <input
              type="number"
              placeholder="Appointment Duration"
              value={appointmentDurationInput}
              onChange={(e) => setAppointmentDuration(e.target.value)}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 'bold',
                border: '1px solid #15558D',
                borderRadius: '10px',
                padding: '5px',
                width: '200px',
              }}
            />
            <input
              type="number"
              placeholder="Break Duration"
              value={breakDurationInput}
              onChange={(e) => setBreakDuration(e.target.value)}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 'bold',
                marginLeft: '10px',
                border: '1px solid #15558D',
                borderRadius: '10px',
                padding: '5px',
                width: '150px',
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <button
            onClick={handleCloseDialog}
            style={{
              border: 'none',
              padding: '10px',
              borderRadius: '5px',
              width: '80px',
            }}
          >
            Cancel
          </button>
          <button
            onClick={handleAddtime}
            color="primary"
            style={{
              border: 'none',
              padding: '10px',
              background: 'rgb(21, 85, 141)',
              borderRadius: '5px',
              width: '80px',
              color: '#fff',
            }}
          >
            Save
          </button>
        </DialogActions>
        {successMessageVisible && (
          <Typography
            variant="h5"
            style={{
              color: '#15558D',
              textAlign: 'center',
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            Time Slots Added!
          </Typography>
        )}
      </Dialog>
    </div>
  )
}

export default ScheduleTimings
