import React, { useState, useEffect } from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Avatar from '@mui/material/Avatar'
import { Link } from 'react-router-dom'
import { logout } from '../../../utils/logout'
import LogoutIcon from '@mui/icons-material/Logout'
import LogoImage from '../../../assets/images/logo.png'

const PatientHeader = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  const handleOptionsClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    logout()
  }

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar sx={{ backgroundColor: 'white', height: '75px' }}>
        <Toolbar>
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <img
              src={LogoImage}
              alt="Logo"
              style={{
                height: '50px',
                marginTop: '7px',
              }}
            />
          </Link>
          <div style={{ flex: 1 }} />
          <IconButton
            onClick={handleOptionsClick}
            sx={{
              marginRight: '16px',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            disableTouchRipple
            disableRipple
          >
            <Avatar
              alt="User Avatar"
              src={require('../../../assets/images/patients/patient.jpg')}
              sx={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                marginTop: '5px',
              }}
            />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              component={Link}
              to="/PatientDashboard"
              onClick={handleClose}
            >
              Dashboard
            </MenuItem>
            <MenuItem
              component={Link}
              to="/PatientProfileSettings"
              onClick={handleClose}
            >
              Profile Settings
            </MenuItem>
            <MenuItem onClick={handleLogout} style={{ color: 'red' }}>
              <LogoutIcon sx={{ marginRight: '8px' }} />
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default PatientHeader
