import React, { useState } from 'react'
import { Alert, Button, Image, Form, Row, Col } from 'react-bootstrap'
import Sidebar from '../SideBar'
import Header from '../Header'
import Footer from '../../Footer'
import placeholderImage from '../../../assets/images/patients/placeholder.png'
import theme from '../../../assets/themes'
import governorates from '../../../utils/governorates'
const PatientProfileSettings = () => {
  const [imageUrl] = useState(placeholderImage)
  const [mobileNumber, setMobileNumber] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [selectedGovernorate, setSelectedGovernorate] = useState('')
  const [selectedBloodType, setSelectedBloodType] = useState('')
  const bloodTypeOptions = ['A+', 'B+', 'AB+', 'O+', 'A-', 'B-', 'AB-', 'O-'] // Add your blood type options here

  const handleFormSubmit = (e) => {
    e.preventDefault()
    // handle form submission here...
  }

  const handleUpdatePhoto = () => {
    console.log('Updating photo...')
  }

  const handleMobileNumberChange = (e) => {
    setMobileNumber(e.target.value)
  }

  const handleMobileNumberBlur = () => {
    // Check if the entered value is a valid mobile number (only numbers)
    if (!/^[0-9]+$/.test(mobileNumber)) {
      setShowAlert(true)
    } else {
      setShowAlert(false)
    }
  }

  return (
    <div>
      {/* Header */}
      <Header />

      {/* Breadcrumb */}
      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/PatientDashboard">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Profile Settings
                  </li>
                </ol>
              </nav>
              <h2 className="breadcrumb-title">Profile Settings Page</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5 col-lg-4 col-xl-3">
              <Sidebar />
            </div>
            <div className="col-md-7 col-lg-8 col-xl-9">
              <Form
                onSubmit={handleFormSubmit}
                style={{
                  backgroundColor: '#fff',
                  margin: 10,
                  borderRadius: 20,
                }}
              >
                <div className="mb-3" style={{ padding: 20 }}>
                  <Row>
                    {/* Column 1 */}
                    <Col md={2}>
                      <Image
                        src={imageUrl}
                        roundedCircle
                        fluid
                        style={{ maxWidth: '90px', maxHeight: '90px' }}
                      />
                    </Col>
                    <Col md={4}>
                      <Row>
                        <Button
                          variant="secondary"
                          onClick={handleUpdatePhoto}
                          style={{
                            backgroundColor: theme.colors.primary,
                            marginTop: '10px',
                          }}
                        >
                          Upload Photo
                        </Button>
                      </Row>
                      <Row>
                        <p
                          style={{
                            marginTop: '10px',
                            fontSize: '10px',
                          }}
                        >
                          Allowed JPG, GIF or PNG, Max size of 2MB
                        </p>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    {/* Column 2 */}
                    <Col
                      md={6}
                      style={{ marginTop: '20px', marginBottom: '10px' }}
                    >
                      <Form.Group>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter First name"
                          required
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Date of Birth</Form.Label>
                        <Form.Control type="date" required />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Email ID</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter email"
                          required
                          disabled
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Governorate</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={(e) =>
                            setSelectedGovernorate(e.target.value)
                          }
                          value={selectedGovernorate}
                        >
                          <option value="">Select Governorate</option>
                          {governorates.map((option, index) => (
                            <option key={index} value={option}>
                              {option}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                      <div style={{ textAlign: 'left', marginTop: '20px' }}>
                        <Button
                          style={{ backgroundColor: theme.colors.primary }}
                          variant="primary"
                          type="submit"
                        >
                          Submit Edits
                        </Button>
                      </div>
                    </Col>

                    {/* Login Fields */}
                    <Col
                      md={6}
                      style={{ marginTop: '20px', marginBottom: '10px' }}
                    >
                      <Form.Group>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter last name"
                          required
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Blood Group</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={(e) => setSelectedBloodType(e.target.value)}
                          value={selectedBloodType}
                        >
                          <option value="">Select Blood Type</option>
                          {bloodTypeOptions.map((option, index) => (
                            <option key={index} value={option}>
                              {option}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Mobile</Form.Label>
                        <Form.Control
                          type="tel"
                          placeholder="Enter mobile number"
                          pattern="[0-9]*"
                          required
                          value={mobileNumber}
                          onChange={handleMobileNumberChange}
                          onBlur={handleMobileNumberBlur}
                          style={{
                            borderColor: showAlert
                              ? theme.colors.lightorange
                              : '',
                          }}
                        />
                        {showAlert && (
                          <Alert
                            variant="info"
                            style={{
                              backgroundColor: theme.colors.lightorange,
                              borderColor: theme.colors.lightorange,
                              marginTop: '5px',
                            }}
                          >
                            Please enter a valid mobile number with only numeric
                            characters.
                          </Alert>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </Form>
            </div>
          </div>
          <br />
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default PatientProfileSettings
