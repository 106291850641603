import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
} from '@mui/material'

const ErrorDialog = ({ open, onClose, errorMessage }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle
        sx={{
          fontWeight: 'bold',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#c21313',
        }}
      >
        Error
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="body1">
            {errorMessage &&
              errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1)}
          </Typography>
          <Button
            onClick={onClose}
            style={{
              marginTop: '10px',
              background: '#c21313',
              borderStyle: 'none',
              borderRadius: '10px',
              padding: '5px',
              color: '#fff',
            }}
          >
            OK
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ErrorDialog
