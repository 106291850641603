import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, Form, Button, Image } from 'react-bootstrap'
import { Autocomplete } from '@mui/material'
import TextField from '@mui/material/TextField'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import dayjs from 'dayjs'
import '../../../App.css'
import Header from '../Header'
import Footer from '../../Footer'
import Sidebar from '../SideBar'
import './BookingSuccess'
import { fetchPatients } from '../../../api/doctor'
import { addAppointment } from '../../../api/doctor'
import ErrorDialog from '../../ErrorDialog'

const Checkout = () => {
  const [patients, setPatients] = useState([])
  const [firstname, setFirstName] = useState('')
  const [lastname, setLastName] = useState('')
  const [error, setError] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  useEffect(() => {
    async function fetchData() {
      try {
        const patientsData = await fetchPatients()
        setPatients(patientsData)
      } catch (error) {
        console.log('Error fetching Patient Data:', error)
      }
    }

    fetchData()
  }, [])

  const handleCloseErrorDialog = () => {
    setError(false)
  }

  const handleBookAppt = (event) => {
    if (event) {
      event.preventDefault()
    }

    if (!fullname || !governorate) {
      alert('Please fill the required input fields')
    } else {
      const names = fullname.split(' ')
      const firstName = names[0]
      setFirstName(firstName)
      const lastName = names[names.length - 1]
      setLastName(lastName)

      const data = {
        patientName: firstName,
        patientFamily: lastName,
        patientPhone: phonenumber,
        patientGovernorate: governorate,
      }
      console.log('data:', data)

      const bookAppt = async () => {
        try {
          const response = await addAppointment(data, timeSlotID)
          console.log('Appointment booked successfully:', response)
          setFullName('')
          setPhoneNumber('')
          setGovernorate('')
          navigateToBooking()
        } catch (error) {
          const message = error.response.data.message
          console.log('Error booking appointment:', message)
          setError(true)
          if (message !== 'The time slot is already taken') {
            setErrorMessage('Error Booking an Appointment')
          } else {
            setErrorMessage(message)
          }
        }
      }
      bookAppt()
    }
  }

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const startTime = searchParams.get('time')
  const endTime = searchParams.get('endTime')
  const date = searchParams.get('date')
  const time = searchParams.get('time')
  const timeSlotID = searchParams.get('timeSlotId')
  const formattedDate = dayjs(date).format('DD MMMM YYYY')

  const [bookingData, setBookingData] = useState({
    imageUrl: require('../../../../src/assets/images/appointment.png'),
  })

  const [fullname, setFullName] = useState('')
  const [governorate, setGovernorate] = useState('')
  const [phonenumber, setPhoneNumber] = useState('')
  const [selectedOption, setSelectedOption] = useState(null)

  const handlePatientSelect = (event, selectedOption) => {
    if (selectedOption) {
      const selectedPatientName = selectedOption.label
      const selectedPatientData = patients.find(
        (patient) =>
          `${patient.firstname} ${patient.lastname}` === selectedPatientName
      )

      if (selectedPatientData) {
        setFullName(
          `${selectedPatientData.firstname} ${selectedPatientData.middlename} ${selectedPatientData.lastname}`
        )
        setGovernorate(selectedPatientData.governorate)
        setPhoneNumber(selectedPatientData.phone_number)
      } else {
        setFullName('')
        setGovernorate('')
        setPhoneNumber('')
      }
    }
  }

  const patientOptions = patients.map((patient) => ({
    value: `${patient.firstname} ${patient.lastname}`,
    label: `${patient.firstname} ${patient.lastname}`,
  }))

  const navigateToBooking = () => {
    navigate(
      `/BookingSuccess?firstname=${firstname}&lastname=${lastname}&date=${date}&startTime=${startTime}&endTime=${endTime}`
    )
  }

  const handleSubmit = (e) => {
    e.preventDefault()
  }

  const navigate = useNavigate()
  return (
    <div>
      <Header />

      <div class="breadcrumb-bar">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-12 col-12">
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/Dashboard">Home</a>
                  </li>
                  <li class="breadcrumb-item">
                    <a href="/AddAppointment">Add Appointment</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Checkout
                  </li>
                </ol>
              </nav>
              <h2 class="breadcrumb-title">Checkout Appointment</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="container-fluid">
          <div className="row align-items-start">
            <div className="col-md-5 col-lg-4 col-xl-3 ">
              <Sidebar />
            </div>
            <div className="col-md-7 col-lg-8 col-xl-9">
              <Container className="checkoutContainer">
                <Row className="justify-content-center">
                  <Col lg={6} className="px-lg-6 ">
                    <Card className="p-lg-2 p-0">
                      <Card.Body>
                        <p className="mb-3 h2">Personal Information</p>
                        <hr className="thinBorder" />
                        <Form onSubmit={handleSubmit}>
                          <Row
                            style={{ marginBottom: '20px', marginTop: '20px' }}
                          >
                            <Col>
                              <Autocomplete
                                value={selectedOption}
                                onChange={handlePatientSelect}
                                options={patientOptions}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Search or select a patient..."
                                  />
                                )}
                              />
                            </Col>
                          </Row>
                          <Row style={{ marginBottom: '20px' }}>
                            <Col>
                              <Form.Group>
                                <Form.Control
                                  type="text"
                                  value={fullname}
                                  onChange={(e) => setFullName(e.target.value)}
                                  required
                                  placeholder="Full Name"
                                  style={{ height: '50px' }}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row style={{ marginBottom: '20px' }}>
                            <Col>
                              <Form.Group>
                                <Form.Control
                                  type="text"
                                  value={governorate}
                                  onChange={(e) =>
                                    setGovernorate(e.target.value)
                                  }
                                  required
                                  placeholder="Governorate"
                                  style={{ height: '50px' }}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Form.Group>
                                <Form.Control
                                  type="tel"
                                  value={phonenumber}
                                  onChange={(e) =>
                                    setPhoneNumber(e.target.value)
                                  }
                                  placeholder="Phone Number (Optional)"
                                  style={{ height: '50px' }}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </Form>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col
                    lg={5}
                    className=" bg-white p-4 rounded my-lg-0 my-5"
                    style={{ border: 'solid 1px #ededed' }}
                  >
                    <p className=" h2">Booking Summary</p>
                    <hr className="thinBorder" />
                    <div className="mb-2  d-flex justify-content-start">
                      <div style={{ marginLeft: '70px' }}>
                        <Image src={bookingData.imageUrl} rounded width={210} />
                      </div>
                    </div>
                    <div
                      className="d-flex flex-column flex-wrap align-content-start"
                      style={{ marginLeft: '90px', marginBottom: '10px' }}
                    >
                      <span
                        style={{ width: 'fit-content', marginRight: '20px' }}
                      >
                        Date: {formattedDate}
                      </span>
                      <span
                        style={{ width: 'fit-content', marginRight: '20px' }}
                      >
                        Time: {time}
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Button
                        className=" font-weight-bold btn btn-primary px-5 py-3"
                        style={{ backgroundColor: '#15558d' }}
                        onClick={handleBookAppt}
                      >
                        Confirm Booking
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
              <ErrorDialog
                open={error}
                onClose={handleCloseErrorDialog}
                errorMessage={errorMessage}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Checkout
