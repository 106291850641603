const governorates = [
  'Beirut',
  'Saida (Sidon)',
  'Nabatieh',
  'Tyre (Sour)',
  'Bekaa (Bekkaa)',
  'Zahle',
  'Byblos (Jbeil)',
  'Tripoli (Trablus)',
  'Aley',
  'Baalbek',
  'Jounieh',
  'Chouf',
  'Mount Lebanon',
  'Akkar',
  'Keserwan',
  'Metn',
  'Zgharta',
  'Bint Jbeil',
  'Hasbaya',
  'Rachaya',
]
export default governorates
