import React from 'react'
import { BsCheckCircleFill } from 'react-icons/bs'
import './ViewAppointment'
import '../../../App.css'
import Header from '../Header'
import Footer from '../../Footer'
import { useNavigate, useLocation } from 'react-router-dom'
import Sidebar from '../SideBar'
import Card from '@mui/material/Card'
import dayjs from 'dayjs'

const PatientBookingSuccess = () => {
  const navigate = useNavigate()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const title = searchParams.get('title')
  const firstname = searchParams.get('firstname')
  const lastname = searchParams.get('lastname')
  const date = searchParams.get('date')
  const formattedDate = dayjs(date).format('DD MMMM YYYY')
  const startTime = searchParams.get('startTime')
  const endtTime = searchParams.get('endTime')

  return (
    <div>
      {/* Header */}
      <Header />
      {/* Header */}

      {/* Breadcrumb */}
      <div class="breadcrumb-bar">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-12 col-12">
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/PatientDashboard">Home</a>
                  </li>
                  {/* You should change the breadcrumb Title */}
                  <li class="breadcrumb-item">
                    <a href="/AddAppointment">Add Appointment</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Booking Success
                  </li>
                </ol>
              </nav>
              {/* You should change the breadcrumb Title */}
              <h2 class="breadcrumb-title">Booking Confirmed</h2>
            </div>
          </div>
        </div>
      </div>
      {/* Breadcrumb */}
      {/* Body Content */}
      <div class="content">
        <div class="container-fluid">
          <div className="row align-items-start">
            <div className="col-md-5 col-lg-4 col-xl-3 ">
              <Sidebar />
            </div>

            <div className="col-md-7 col-lg-8 col-xl-9">
              <Card style={{ borderRadius: 20 }}>
                {' '}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '60vh',
                  }}
                >
                  <form
                    style={{
                      margin: '0 auto',
                      textAlign: 'center',
                    }}
                  >
                    <BsCheckCircleFill
                      class="done"
                      color="green"
                      style={{ fontSize: '30px', marginTop: '20px' }}
                    />{' '}
                    <br /> <br />
                    <a style={{ fontWeight: 'bold', fontSize: 30 }}>
                      Appointment booked Successfully!
                    </a>{' '}
                    <br />
                    <br />
                    <a
                      style={{
                        fontWeight: 'bold',
                        fontSize: 25,
                        fontStyle: 'Verdana, Arial, Helvetica, sans-serif',
                      }}
                    >
                      {' '}
                      with {`${title} ${firstname} ${lastname}`}
                    </a>{' '}
                    <br />
                    <a style={{ fontSize: 20 }}>on</a>
                    <a style={{ fontWeight: 'bold', fontSize: '20px' }}>
                      {' '}
                      {`${formattedDate} ${startTime}-${endtTime}`}
                    </a>{' '}
                    <br /> <br />
                    <button
                      className="btn btn-primary "
                      style={{
                        backgroundColor: '#15558d',
                        fontSize: '20px',
                        marginBottom: '20px',
                      }}
                      onClick={() => navigate('/ViewPatientAppointment')}
                    >
                      View Appointment
                    </button>
                  </form>
                </div>
              </Card>{' '}
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
      {/* Footer */}
    </div>
  )
}

export default PatientBookingSuccess
