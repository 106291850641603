import axios from './axios'
import { getToken } from '../utils/auth'
import Doctor from '../utils/doctor_info'
export async function fetchPatients() {
  try {
    const token = getToken
    const doc = new Doctor()
    const doctorId = doc.id
    const response = await axios.get(`/api/doctors/${doctorId}/patients`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data.bookingPatients
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error
  }
}
export async function changePassword(currentPassword, newPassword) {
  try {
    const token = getToken
    const doc = new Doctor()
    const userId = doc.user_id
    const response = await axios.patch(
      `/api/users/change_password/${userId}`,
      {
        currentPassword: currentPassword,
        newPassword: newPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data
  } catch (error) {
    console.error('Error changing password', error)
    throw error
  }
}
export async function editProfileSettings(doctorData) {
  try {
    const token = getToken
    const doc = new Doctor()
    const doctorId = doc.id
    const response = await axios.patch(
      `/api/doctors/update_profile/${doctorId}`,
      doctorData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    if (response.status === 200) {
      localStorage.setItem('doctor', JSON.stringify(doctorData))
    }
    return response
  } catch (error) {
    console.error('error:', error)
    throw error
  }
}

export async function fetchTimeTemplates() {
  try {
    const token = getToken
    const doc = new Doctor()
    const doctorId = doc.id
    const response = await axios.get(`/api/time_templates/doctor/${doctorId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data.timeTemplates
  } catch (error) {
    console.error('Error fetching time templates:', error)
    throw error
  }
}

export async function addTimeTemplate(data) {
  try {
    const token = getToken
    const doc = new Doctor()
    const doctorId = doc.id
    const response = await axios.post(
      `/api/time_templates/doctor/${doctorId}/add`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data
  } catch (error) {
    console.error('Error adding time template:', error)
    throw error
  }
}

export async function updateTimeTemplate(time_Template_ID, data) {
  try {
    const token = getToken
    const doc = new Doctor()
    const doctorId = doc.id
    const response = await axios.patch(
      `/api/time_templates/doctor/${doctorId}/${time_Template_ID}/update`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data
  } catch (error) {
    console.error('Error updating time template:', error)
    throw error
  }
}

export async function addAppointment(data, timeSlotID) {
  try {
    const token = getToken
    const doc = new Doctor()
    const doctorId = doc.id
    const response = await axios.post(
      `/api/doctors/${doctorId}/appointments/book/time_slots/${timeSlotID}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data
  } catch (error) {
    console.error('Error adding time template:', error)
    throw error
  }
}
export async function fetchTimeSlots(selectedDate) {
  try {
    const token = getToken
    const doc = new Doctor()
    const doctorId = doc.id
    const response = await axios.get(
      `/api/doctor_time_slots/doctor/${doctorId}/day/${selectedDate}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data.timeSlots
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}
export async function fetchNearestTimeSlot() {
  try {
    const token = getToken
    const doc = new Doctor()
    const doctorId = doc.id
    const response = await axios.get(
      `/api/doctor_time_slots/doctor/${doctorId}/nearest`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return response.data.timeSlot
  } catch (error) {
    console.error('Error fetching nearest time slot:', error)
    throw error
  }
}

export async function searchDoctor(data) {
  try {
    const token = getToken
    const response = await axios.post(`/api/doctors/search`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data.filteredDoctors
  } catch (error) {
    console.error('Error fetching Doctors Info:', error)
    throw error
  }
}

export async function generateOneDayTimeSlots(selectedDay) {
  try {
    const token = getToken
    const doc = new Doctor()
    const doctorId = doc.id

    const response = await axios.post(
      `/api/doctor_time_slots/doctor/${doctorId}/create/day/${selectedDay}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data
  } catch (error) {
    console.log('Error generate new time slot ', error)
  }
}

export async function createTimingsforSpecificPeriod(selectedDay) {
  try {
    const token = getToken
    const doc = new Doctor()
    const doctorId = doc.id
    const response = await axios.post(
      `/api/doctor_time_slots/doctor/${doctorId}/create/${selectedDay}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data
  } catch (error) {
    console.log('Error generate new time slot ', error)
  }
}

export async function getDayAppointments(date) {
  try {
    const token = getToken
    const doc = new Doctor()
    const doctorId = doc.id
    const response = await axios.get(
      `/api/doctors/${doctorId}/appointments/day/${date}`,
      {
        headers: {
          Authorization: ` Bearer ${token}`,
        },
      }
    )
    return response.data.filteredAppointments
  } catch (error) {
    console.log('error getting Day appointments', error)
  }
}

export async function getWeekAppointments(date) {
  try {
    const token = getToken
    const doc = new Doctor()
    const doctorId = doc.id
    const response = await axios.get(
      `/api/doctors/${doctorId}/appointments/week/${date}`,
      {
        headers: {
          Authorization: ` Bearer ${token}`,
        },
      }
    )
    return response.data.filteredAppointments
  } catch (error) {
    console.log('error getting Week appointments', error)
  }
}

export async function getMonthAppointments(date) {
  try {
    const token = getToken
    const doc = new Doctor()
    const doctorId = doc.id
    const response = await axios.get(
      `/api/doctors/${doctorId}/appointments/month/${date}`,
      {
        headers: {
          Authorization: ` Bearer ${token}`,
        },
      }
    )
    return response.data.filteredAppointments
  } catch (error) {
    console.log('error getting month appointments', error)
  }
}
export async function fetchAllDoctors() {
  try {
    const token = getToken
    const response = await axios.get('/api/doctors', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data.data
  } catch (error) {
    console.error('Error fetching all doctors:', error)
    throw error
  }
}
export async function fetchAverageRating() {
  try {
    const token = getToken
    const doctorId = doc.id
    const doc = new Doctor()

    const response = await axios.get(
      `/api/reviews/doctor/${doctorId}/average`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data.averageRatings
  } catch (error) {
    console.error('Error fetching average rating:', error)
    throw error
  }
}
export async function registerDoctor(personalInfo, emailInfo) {
try {
  const response = await axios.post('/api/doctors/register', {
    title: personalInfo[0]['title'],
    firstname: personalInfo[0]['firstname'],
    middlename: personalInfo[0]['middlename'],
    lastname: personalInfo[0]['lastname'],
    gender: personalInfo[0]['gender'],
    email: emailInfo[0]['email'],
    phone: emailInfo[0]['phoneNumber'],
    location: [33.255904, 35.275933],
  })
  if (!response.data.success) {
    window.alert('An error occurred during Register.')
    return
  }
} catch (error) {
  console.log(error)
  if (error.response && error.response.data) {
    window.alert(
      error.response.data.message ||
        'An error occurred during Register.'
    )
  } else {
    window.alert('An error occurred during Register.')
  }
  return
}}