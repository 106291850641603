import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, Form, Button, Image } from 'react-bootstrap'
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  IconButton,
  Rating,
  Typography,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { useNavigate, useLocation } from 'react-router-dom'
import dayjs from 'dayjs'
import '../../../App.css'
import Header from '../Header'
import Footer from '../../Footer'
import Sidebar from '../SideBar'
import './BookingSuccess'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import ImageIcon from '@mui/icons-material/ImageOutlined'
import DescriptionIcon from '@mui/icons-material/Description'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { addAppointment } from '../../../api/patient'
import ErrorDialog from '../../ErrorDialog'
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const customLinks = {
  'blood count': '#',
  'liver Function test': '#',
  'epinephrine Tolerance Ttst': '#',
  echocardiography: '#',
  angiocardiography: '#',
  'pulmonary function test': '#',
  'X-ray': '#',
}

const names = [
  'blood count',
  'liver Function test',
  'epinephrine Tolerance Ttst',
  'echocardiography',
  'angiocardiography',
  'pulmonary function test',
  'X-ray',
]

const PatientCheckout = () => {
  const [error, setError] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')

  const handleBookAppt = (event) => {
    event.preventDefault()

    const bookAppt = async () => {
      const data = {}
      try {
        //const response = await addAppointment(data, timeSlotID)
        //console.log('Appointment booked successfully:', response)
        navigateToBooking()
      } catch (error) {
        //const message = error.response.data.message
        console.log('Error booking appointment:')
        setError(true)
        /* if (message !== 'The time slot is already taken') {
          setErrorMessage('Error Booking an Appointment')
        } else {
          setErrorMessage(message)
        }*/
      }
    }
    bookAppt()
  }

  const handleCloseErrorDialog = () => {
    setError(false)
  }

  const [selectedNames, setSelectedNames] = useState([])

  const handleSelectedNamesChange = (event) => {
    setSelectedNames(event.target.value)
  }

  const renderSelectedNameInputs = () => {
    return selectedNames.map((name, index) => (
      <div style={{ display: 'flex' }}>
        <a
          href={customLinks[name]}
          target="_self"
          rel="noopener noreferrer"
          style={{ color: '#15558d' }}
        >
          <label
            style={{
              marginTop: '10px',
              fontWeight: 'bold',
              border: '2px solid #757575',
              borderRadius: '10px',
              width: '350px',
              padding: '10px',
              cursor: 'pointer',
            }}
          >
            {name}
          </label>
        </a>
        <IconButton
          onClick={() => handleRemoveName(index)}
          color="error"
          aria-label="remove"
        >
          <DeleteIcon />
        </IconButton>
      </div>
    ))
  }

  const handleRemoveName = (index) => {
    const updatedNames = [...selectedNames]
    updatedNames.splice(index, 1)
    setSelectedNames(updatedNames)
  }

  const maxTotalSizeMB = 10
  const [selectedFiles, setSelectedFiles] = useState([])
  const [totalSizeMB, setTotalSizeMB] = useState(0)

  const handleFileInput = (event) => {
    const files = event.target.files
    const newTotalSizeMB =
      totalSizeMB +
      [...files].reduce((acc, file) => acc + file.size / (1024 * 1024), 0)

    if (newTotalSizeMB > maxTotalSizeMB) {
      alert(
        `Total file size exceeds ${maxTotalSizeMB}MB. Please choose smaller files.`
      )
      event.target.value = ''
      return
    }
    const newSelectedFiles = Array.from(files).map((file) => ({
      file,
      name: file.name,
    }))
    setSelectedFiles([...selectedFiles, ...newSelectedFiles])
    setTotalSizeMB(newTotalSizeMB)
  }

  const removeFile = (fileName) => {
    const updatedTotalSizeMB =
      totalSizeMB -
      selectedFiles.find((file) => file.name === fileName).file.size /
        (1024 * 1024)
    const updatedFiles = selectedFiles.filter((file) => file.name !== fileName)
    setSelectedFiles(updatedFiles)
    setTotalSizeMB(updatedTotalSizeMB)
  }

  const getFileIcon = (fileName) => {
    const fileExtension = fileName.split('.').pop().toLowerCase()

    if (fileExtension === 'pdf') {
      return <PictureAsPdfIcon style={{ color: '#15558d' }} />
    } else if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
      return <ImageIcon style={{ color: '#15558d' }} />
    } else if (['doc', 'docx', 'odt'].includes(fileExtension)) {
      return <DescriptionIcon style={{ color: '#15558d' }} />
    } else {
      return <InsertDriveFileIcon style={{ color: '#15558d' }} />
    }
  }

  const [bookingData, setBookingData] = useState({
    name: 'Dr. Darren Elder',
    service: 'Massage',
    location: 'lebanon',
    reviewStars: 4,
    imageUrl: require('../../../../src/assets/images/patients/patient.jpg'),
  })

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const date = searchParams.get('date')
  const formattedDate = dayjs(date).format('DD MMMM YYYY')
  const startTime = searchParams.get('time')
  const endTime = searchParams.get('endTime')
  const timeSlotID = searchParams.get('timeSlotId')
  const profile = searchParams.get('profile')
  const title = searchParams.get('title')
  const averageRating = searchParams.get('rating')
  const firstname = searchParams.get('firstname')
  const lastname = searchParams.get('lastname')
  const locationName = searchParams.get('location')

  const navigateToBooking = () => {
    navigate(
      `/PatientBookingSuccess?title=${title}&firstname=${firstname}&lastname=${lastname}&date=${date}&startTime=${startTime}&endTime=${endTime}`
    )
  }

  const handleSubmit = (e) => {
    e.preventDefault()
  }

  const navigate = useNavigate()
  return (
    <div>
      {/* Header */}
      <Header />
      {/* Header */}

      {/* Breadcrumb */}
      <div class="breadcrumb-bar">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-12 col-12">
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/PatientDashboard">Home</a>
                  </li>
                  <li class="breadcrumb-item">
                    <a href="/PatientBooking">Add Appointment</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Checkout
                  </li>
                </ol>
              </nav>
              <h2 class="breadcrumb-title">Checkout Appointment</h2>
            </div>
          </div>
        </div>
      </div>
      {/* Breadcrumb */}
      {/* Body Content */}
      <div class="content">
        <div class="container-fluid">
          <div className="row align-items-start">
            <div className="col-md-5 col-lg-4 col-xl-3 ">
              <Sidebar />
            </div>
            <div className="col-md-7 col-lg-8 col-xl-9">
              <Container className="checkoutContainer">
                <Row className="justify-content-center">
                  <Col lg={6} className="px-lg-6 ">
                    <Card className="p-lg-2 p-0">
                      <Card.Body>
                        <p className="mb-3 h2">Personal Information</p>
                        <hr className="thinBorder" />
                        <div style={{ marginTop: '30px' }}>
                          <FormControl style={{ width: '100%' }}>
                            <InputLabel id="names-select-label">
                              Select Medical Records
                            </InputLabel>
                            <Select
                              labelId="names-select-label"
                              id="names-select"
                              multiple
                              value={selectedNames}
                              onChange={handleSelectedNamesChange}
                              input={
                                <OutlinedInput label="Select Medical Records" />
                              }
                              renderValue={(selected) => selected.join(', ')}
                              MenuProps={MenuProps}
                            >
                              {names.map((name) => (
                                <MenuItem key={name} value={name}>
                                  <Checkbox
                                    checked={selectedNames.indexOf(name) > -1}
                                  />
                                  <ListItemText primary={name} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <div style={{ marginTop: '10px' }}>
                            {selectedNames.length > 0 &&
                              renderSelectedNameInputs()}
                          </div>
                        </div>
                        <Form onSubmit={handleSubmit}>
                          {/* start */}

                          <div
                            className="d-flex justify-content-between align-content-center"
                            style={{ marginTop: '30px' }}
                          >
                            <input
                              type="file"
                              id="file"
                              multiple
                              onChange={handleFileInput}
                              style={{ display: 'none' }}
                            />
                            <div>
                              {' '}
                              <p>Upload Your Medical Records</p>
                              <label
                                for="file"
                                className="mb-0 font-weight-bold rounded py-3 "
                                style={{
                                  maxHeight: '50px',
                                  paddingRight: '35px',
                                  paddingLeft: '35px',
                                  color: 'white',
                                  backgroundColor: '#15558d',
                                }}
                              >
                                Upload
                              </label>
                            </div>

                            <div style={{ maxWidth: '200px' }}>
                              {/* Display selected files */}
                              {selectedFiles.map((file) => (
                                <div key={file.name}>
                                  {getFileIcon(file.name)} {file.name}
                                  <span
                                    style={{
                                      cursor: 'pointer',
                                      margin: '5px',
                                      color: '#15558d',
                                    }}
                                    onClick={() => removeFile(file.name)}
                                  >
                                    x
                                  </span>
                                </div>
                              ))}
                            </div>
                          </div>

                          {/* end */}
                        </Form>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col
                    lg={5}
                    className=" bg-white p-4 rounded my-lg-0 my-5"
                    style={{ border: 'solid 1px #ededed' }}
                  >
                    <p className=" h2">Booking Summary</p>
                    <hr className="thinBorder" />
                    <div className="mb-3 p-2  d-flex justify-content-start">
                      <div>
                        <Image src={profile} rounded width={100} />
                      </div>
                      <div className="infostarloc" style={{ marginLeft: 20 }}>
                        <div>
                          {' '}
                          <p className="mr-left font-weight-semi-bold mb-0 h4">
                            {`${title} ${firstname} ${lastname}`}
                          </p>
                          <Typography variant="body2" color="text.secondary">
                            <Rating
                              name="half-rating"
                              value={averageRating}
                              precision={0.5}
                              readOnly
                            />
                          </Typography>
                          <p>
                            <FaMapMarkerAlt /> {locationName}
                          </p>{' '}
                        </div>
                      </div>
                    </div>
                    <div
                      className="d-flex flex-column flex-wrap align-content-center"
                      style={{ marginTop: '-20px' }}
                    >
                      <span
                        style={{ width: 'fit-content', marginRight: '20px' }}
                      >
                        Date: {formattedDate}
                      </span>
                      <span
                        style={{ width: 'fit-content', marginRight: '20px' }}
                      >
                        Time: {startTime}
                      </span>
                    </div>
                    <div className="d-flex justify-content-center mt-5">
                      <Button
                        className=" font-weight-bold btn btn-primary px-5 py-3"
                        style={{ backgroundColor: '#15558d' }}
                        onClick={handleBookAppt}
                      >
                        Confirm Booking
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
              <ErrorDialog
                open={error}
                onClose={handleCloseErrorDialog}
                errorMessage={errorMessage}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
      {/* Footer */}
    </div>
  )
}

export default PatientCheckout
