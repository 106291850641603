class Patient {
  id
  user_id
  profile
  firstname
  middlename
  lastname
  gender
  dateOfBirth
  blood
  governorate
  email
  phone


  constructor() {
    let user

    try {
      user = JSON.parse(localStorage.getItem('patient'));
      this.id = user._id
      this.user_id = user.user_id
      this.profile = user.profile_image
      this.firstname=user.firstname
      this.middlename=user.middlename
      this.lastname=user.lastname
      this.gender=user.gender
      this.dateOfBirth=user.date_of_birth
      this.blood=user.blood_group
      this.governorate=user.governorate
      this.email=user.email
      this.phone=user.phone_number
    } catch (error) {
      console.error('Error parsing user data from local storage:', error)
    }
  }
}
export default Patient