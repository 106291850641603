import { jwtDecode } from 'jwt-decode'
import Doctor from './doctor_info'
import Patient from './patient_info'
// Function to extract the JWT token ( it may be changed later because local storage is not secure )
export const getToken = localStorage.getItem('token')

export const isTokenValid = () => {
  const token = getToken
  if (!token) {
    return false
  }
  const decodedToken = jwtDecode(token)
  const currentTime = Date.now() / 1000
  return decodedToken.exp > currentTime
}

export const isDoctorValid = () => {
  const doc = new Doctor()

  const docID = doc.user_id
  console.log('D', docID)
  return !!docID
}
export const isPatientValid = () => {
  const pat = new Patient()

  const patID = pat.user_id
  console.log('P', patID)
  return !!patID
}
